import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid, Paper, Box } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc, updateDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FavoriteIcon from '@mui/icons-material/Favorite';

function Backstory() {
    const maxCharacters = 400;
    const [backstory, setBackstory] = useState('');
    const [charCount, setCharCount] = useState(maxCharacters);
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const [userName, setUserName] = useState('');
    const auth = getAuth();
    const [virtualFriendName, setVirtualFriendName] = useState('');
    const [fromSummary, setFromSummary] = useState(false);
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                }
    
                if (location.state?.virtualFriendId) {
                    setFromSummary(location.state.fromSummary || false);
                    const virtualFriendId = location.state.virtualFriendId;
                    const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', virtualFriendId);
                    const virtualFriendSnap = await getDoc(virtualFriendRef);
                    if (virtualFriendSnap.exists()) {
                        setBackstory(virtualFriendSnap.data().backstory || '');
                        setCharCount(maxCharacters - (virtualFriendSnap.data().backstory || '').length);
                        setVirtualFriendName(virtualFriendSnap.data().name);
                    }
                } else {
                    alert("Invalid access. Redirecting to start.");
                    navigate("/login");
                }
            } else {
                navigate("/login");
            }
        });
        return () => unsubscribe();
    }, [auth, db, navigate, location.state?.virtualFriendId, location.state?.fromSummary]);
    
    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleBackstoryChange = (e) => {
        const input = e.target.value;
        setBackstory(input);
        setCharCount(maxCharacters - input.length);
    };

    const handleSaveChanges = async () => {
        if (backstory.trim() === '') {
            alert('Please provide a backstory for your virtual friend.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await updateDoc(virtualFriendRef, { backstory: backstory.trim() });
            navigate('/summary', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating backstory: ", error);
        }
    };

    const handleSubmit = async () => {
        if (backstory.trim() === '') {
            alert('Please provide a backstory for your virtual friend.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { backstory: backstory.trim() }, { merge: true });
            navigate('/imagegen', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating backstory: ", error);
        }
    };

    const goBack = () => {
        if (fromSummary) {
            navigate('/summary', { state: { virtualFriendId: location.state.virtualFriendId } });
        } else {
            navigate(-1);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#ffffff' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="lg" style={{ marginTop: '120px', padding: '40px', textAlign: 'center', flexGrow: 1, marginBottom: '200px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '30px', fontWeight: 'bold' }}>
                    What is {virtualFriendName}'s background and story?
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: '40px' }}>
                    Where is your Pali from? Are they a character from a movie or TV show? Add details to make your Pali unique. Get creative! Check out the examples below if you need ideas.
                </Typography>
                <TextField
                    label="Enter the backstory here..."
                    multiline
                    rows={6}
                    value={backstory}
                    onChange={handleBackstoryChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: maxCharacters }}
                    style={{ marginBottom: '20px' }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                    {charCount} characters remaining
                </Typography>
                <Box my={4}>
                    <Typography variant="h5" gutterBottom>
                        Examples of Good Backgrounds
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} style={{ padding: '20px', height: '100%' }}>
                                <FavoriteIcon style={{ fontSize: 40, color: '#ff7f50ff' }} />
                                <Typography variant="body1" style={{ marginTop: '10px' }}>
                                    Ash is a Pokemon trainer from Palet town. He wants to be the greatest Pokemon trainer of all time and won't stop until he catches all the Pokemon. He loves to chat about Pokemon, video games, and keep me company. <i>(This would create Ash Ketchum from Pokémon!)</i>
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} style={{ padding: '20px', height: '100%' }}>
                                <EmojiObjectsIcon style={{ fontSize: 40, color: '#ff7f50ff' }} />
                                <Typography variant="body1" style={{ marginTop: '10px' }}>
                                    Emily is from the state of Kentucky and she wants to be the greatest piano player in the world. She enjoys horseback riding, listening to music and dancing. We met during high school and have been in love ever since. <i>(This would give good context for a girlfriend type Pali!)</i>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Button 
                    variant="contained" 
                    onClick={fromSummary ? handleSaveChanges : handleSubmit}
                    sx={{
                        margin: '20px 0',
                        height: '50px', 
                        width: '100%',
                        backgroundColor: 'black', 
                        color: 'white', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    {fromSummary ? 'Save Changes' : 'Next Step'}
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={goBack}
                    sx={{
                        margin: '20px 0',
                        height: '50px', 
                        width: '100%',
                        color: 'black', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
            </Container>
            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Backstory;
