import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid, CircularProgress, Card, CardMedia, CardContent, Box, Paper } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, setDoc, getFirestore, getDoc, arrayUnion, increment } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

function ImageDescription() {
    const maxCharacters = 450;
    const [imageDescription, setImageDescription] = useState('');
    const [charCount, setCharCount] = useState(maxCharacters);
    const [generatedImages, setGeneratedImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [virtualFriendName, setVirtualFriendName] = useState('');
    const [isFinalizing, setIsFinalizing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [imageGenerationCount, setImageGenerationCount] = useState(0);
    const [fromSummary, setFromSummary] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const auth = getAuth();
    const [userName, setUserName] = useState('');
    const functions = getFunctions();
    const db = getFirestore();
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const docSnap = await getDoc(doc(db, "users", user.uid));
                if (docSnap.exists()) {
                    setUserName(docSnap.data().name);
                }
    
                const storedVirtualFriendId = localStorage.getItem('virtualFriendId');
                const virtualFriendId = location.state?.virtualFriendId || storedVirtualFriendId;
    
                if (virtualFriendId) {
                    localStorage.setItem('virtualFriendId', virtualFriendId);
    
                    const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', virtualFriendId);
                    const virtualFriendSnap = await getDoc(virtualFriendRef);
    
                    if (virtualFriendSnap.exists()) {
                        setVirtualFriendName(virtualFriendSnap.data().name);
    
                        const virtualFriendData = virtualFriendSnap.data();
                        const imageGenerationAttempts = virtualFriendData.imageGenerationAttempts || 0;
                        setImageGenerationCount(imageGenerationAttempts);
    
                        const storedImages = virtualFriendData.imageUrls || [];
                        setGeneratedImages(storedImages);
                        if (storedImages.length > 0) {
                            setSelectedImage(storedImages[storedImages.length - 1]);
                            setIsSelected(true);
                        }
                    } else {
                        console.error("Virtual friend document does not exist.");
                    }
                } else {
                    console.error("No virtualFriendId provided.");
                    navigate("/login");
                }
            } else {
                navigate("/login");
            }
        });
    
        return () => unsubscribe();
    }, [auth, db, navigate, location.state]);
    
    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleImageDescriptionChange = (e) => {
        const input = e.target.value;
        setImageDescription(input);
        setCharCount(maxCharacters - input.length);
    };

    const handleImageGeneration = async () => {
        if (imageGenerationCount >= 3) {
            alert('You have reached the limit of 3 image generation attempts.');
            return;
        }
    
        if (imageDescription.trim() === '') {
            alert('Please describe the image for your virtual friend.');
            return;
        }
    
        setIsLoading(true);
        const generateImage = httpsCallable(functions, 'generateImage');
        try {
            const result = await generateImage({ description: imageDescription });
            if (result.data && result.data.imageUrl) {
                setGeneratedImages(prevImages => [...prevImages, result.data.imageUrl]);
                setIsSelected(false);
    
                const newCount = imageGenerationCount + 1;
                setImageGenerationCount(newCount);
    
                const virtualFriendId = location.state.virtualFriendId;
                const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);
                await setDoc(virtualFriendRef, { 
                    imageUrls: arrayUnion(result.data.imageUrl),
                    imageGenerationAttempts: increment(1)
                }, { merge: true });
            }
        } catch (error) {
            console.error("Error generating image: ", error);
            alert("Failed to generate image.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (!selectedImage) {
            alert('Please select an image first.');
            return;
        }
        const virtualFriendId = location.state.virtualFriendId;
        setIsFinalizing(true);
        try {
            const processFinalizedImage = httpsCallable(functions, 'processFinalizedImage');
            const response = await processFinalizedImage({
                userId: auth.currentUser.uid,
                virtualFriendId,
                imageUrl: selectedImage
            });
            if (response.data && response.data.permanentImageUrl) {
                const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);
                await setDoc(virtualFriendRef, { imageUrl: response.data.permanentImageUrl }, { merge: true });
            }
            navigate(fromSummary ? '/summary' : '/getmessage', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error processing final image: ", error);
            alert('Failed to process the final image.');
        } finally {
            setIsFinalizing(false);
        }
    };

    const selectImage = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsSelected(true);
    };

    const goBack = () => {
        if (fromSummary) {
            navigate('/summary', { state: { virtualFriendId: location.state.virtualFriendId } });
        } else {
            navigate(-1);
        }
    };

    const betaTestSkip = () => {
        navigate('/getmessage', { state: { virtualFriendId: location.state.virtualFriendId } });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#ffffff' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" style={{ padding: '40px', textAlign: 'center', flexGrow: 1, marginTop: '120px', marginBottom: '40px' }}>
                <Typography variant="h3" gutterBottom style={{ marginBottom: '30px', fontWeight: 'bold' }}>
                    Describe {virtualFriendName}'s Appearance
                </Typography>
                <Typography variant="h6" style={{ marginBottom: '30px', color: '#666' }}>
                    Provide details like eye color, hair color, and setting to generate a perfect image of your virtual friend. The more details you provide, the better image that will be created!
                </Typography>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '30px', backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h6" gutterBottom>
                        <b>Example Description:</b>
                    </Typography>
                    <Typography variant="body1">
                        <i>Create a photo realistic image of a young woman in her 20s with vibrant green eyes and long blonde hair, cascading in soft waves. She has a gentle, welcoming smile that lights up her face, revealing a sprinkling of freckles across her cheeks. Her expression is one of kindness and warmth, inviting a sense of trust and friendliness.</i>
                    </Typography>
                </Paper>
                <Typography variant="h6" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
                    You can generate up to 3 images. Attempts left: {3 - imageGenerationCount}
                </Typography>
                <TextField
                    label="Enter the image description here..."
                    multiline
                    rows={4}
                    value={imageDescription}
                    onChange={handleImageDescriptionChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: maxCharacters }}
                    style={{ marginBottom: '20px' }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                    {charCount} characters remaining
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleImageGeneration}
                    disabled={isLoading}
                    sx={{
                        margin: '20px 0',
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: '#ff7f50ff' }
                    }}
                >
                    Generate Image
                </Button>
                {isLoading && <CircularProgress style={{ display: 'block', margin: '20px auto' }} />}
                {generatedImages.length > 0 && (
                    <Typography variant="h5" style={{ textAlign: 'center', margin: '20px 0' }}>
                        Select an Image:
                    </Typography>
                )}
                <Grid container spacing={2} style={{ justifyContent: 'center', marginBottom: '30px' }}>
                    {generatedImages.map((image, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <img
                                src={image}
                                alt={`Generated Virtual Friend ${index + 1}`}
                                style={{
                                    maxWidth: '256px',
                                    maxHeight: '256px',
                                    width: 'auto',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    border: selectedImage === image ? '5px solid #ff7f50' : 'none',
                                    borderRadius: '8px'
                                }}
                                onClick={() => selectImage(image)}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Button
                    variant="outlined"
                    onClick={goBack}
                    sx={{
                        margin: '10px',
                        color: 'black',
                        '&:hover': { backgroundColor: '#ff7f50ff' }
                    }}
                >
                    Go Back
                </Button>
                {isSelected && (
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isFinalizing}
                        sx={{
                            margin: '10px',
                            backgroundColor: '#4caf50',
                            color: 'white',
                            '&:hover': { backgroundColor: '#ff7f50ff' }
                        }}
                    >
                        {isFinalizing ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Finalize Image'}
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={betaTestSkip}
                    sx={{
                        margin: '10px',
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: '#388e3c' }
                    }}
                >
                    I Don't Want a Picture
                </Button>
                <Typography variant="h6" style={{ marginBottom: '10px', marginTop: '30px' }}>
                    Looking for inspiration? Check out these examples:
                </Typography>
            </Container>

            <Container maxWidth={false} style={{ padding: '20px', marginTop: '10px', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif', marginBottom: '20px' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ maxWidth: 256, flexGrow: 1, margin: 'auto' }}>
                            <CardMedia
                                component="img"
                                image="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/users%2F3L3h4PPRqPN8391DoNpcf7ySMg92%2Ffriends%2F9aZ7lIVWJv2d0Zyg59jW%2Fimage.jpg?alt=media&token=d01657f4-12a9-4c14-8152-b15a1b2b0b27"
                                alt="Example Image 1"
                                sx={{ height: 256, width: 256, objectFit: 'contain' }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Susan
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Give me an anime style image of a girl with brown hair. Give her brown eyes and a friendly smile. Have her give the peace sign. Have her wearing a maid costume.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ maxWidth: 256, flexGrow: 1, margin: 'auto' }}>
                            <CardMedia
                                component="img"
                                image="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/users%2F3L3h4PPRqPN8391DoNpcf7ySMg92%2Ffriends%2FEnUBveRtExeXJEybZWNg%2Fimage.jpg?alt=media&token=60bbc096-9a46-4da5-bafc-6da40faf82bb"
                                alt="Example Image 2"
                                sx={{ height: 256, width: 256, objectFit: 'contain' }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Jim
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Give me a realistic photo image of a man who looks like Jim from the American TV series “The Office”. Have him looking at me doing the smirk he always does to the camera.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ maxWidth: 256, flexGrow: 1, margin: 'auto' }}>
                            <CardMedia
                                component="img"
                                image="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/users%2F3L3h4PPRqPN8391DoNpcf7ySMg92%2Ffriends%2FS42mEiKq6kiBy35AThWL%2Fimage.jpg?alt=media&token=295cb682-8d0d-4eb6-8c71-2a91d61fe881"
                                alt="Example Image 3"
                                sx={{ height: 256, width: 256, objectFit: 'contain' }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Sarah
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Give me a realistic photo image of a woman in her 20s who has green eyes and dark blonde hair. Have her smiling at the camera like she is sending me a selfie!
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default ImageDescription;
