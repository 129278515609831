import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PaymentIcon from '@mui/icons-material/Payment';
import ChatIcon from '@mui/icons-material/Chat';
import PsychologyIcon from '@mui/icons-material/Psychology';
import StarIcon from '@mui/icons-material/Star';
import CreateIcon from '@mui/icons-material/Create';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FeedbackIcon from '@mui/icons-material/Feedback';
import useScrollToTop from './useScrollToTop';

function Tutorial() {
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const [userName, setUserName] = useState('');

    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                }
            } else {
                navigate('/login');
            }
        });
        return unsubscribe;
    }, [auth, db, navigate]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="lg" style={{ flex: 1, marginTop: '100px', padding: '20px', textAlign: 'center' }}>
                <Typography variant="h3" gutterBottom style={{ marginBottom: '30px', fontWeight: 'bold' }}>
                    How <b>Pali</b> Works!
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <DesignServicesIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Design Your Pali</b></Typography>
                            <Typography variant="body1">Create your own personalized Pali with customizable settings, communication style, and unique character details.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <PsychologyIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Customize for Mental Health</b></Typography>
                            <Typography variant="body1">Give your Pali a supportive backstory and appearance to help you with mental health and well-being.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <ChatIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Start the Conversation</b></Typography>
                            <Typography variant="body1">Finalize your Pali's details and begin your journey. Your new friend will text you, and you can communicate via SMS.</Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Container style={{ marginTop: '40px', marginBottom: '60px', textAlign: 'center' }}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => navigate('/customize')} 
                        sx={{
                            backgroundColor: '#ff7f50ff',
                            '&:hover': { backgroundColor: '#ff7f50dd' },
                            fontSize: '1.3rem',
                            padding: '10px 20px',
                            transform: 'scale(1.1)',
                            fontWeight: 'bold',
                        }}
                    >
                        Get Started
                    </Button>
                </Container>

                <Typography variant="h4" gutterBottom style={{ marginBottom: '30px', marginTop: '50px', fontWeight: 'bold' }}>
                    How to Create the Best <b>Pali</b> for Mental Health Support
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <PsychologyIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Envision Your Ideal Support</b></Typography>
                            <Typography variant="body1">Imagine the characteristics and personality traits that would best support your mental health. Use the customization options to create a Pali that meets your needs.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <StarIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Create a Familiar Face</b></Typography>
                            <Typography variant="body1">Base your Pali on a beloved character or someone you admire. Detail their origin, traits, and behaviors to make them feel familiar and comforting.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <CreateIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Detailed Customizations</b></Typography>
                            <Typography variant="body1">Provide specific inputs about your preferences to ensure your Pali embodies the support and understanding you need for mental well-being. Create any kind of friend you want.</Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <div style={{ marginTop: '40px' }}>
                    <Typography variant="h4" style={{ marginBottom: '50px', fontWeight: 'bold' }}>Ideas for Talking to Your Pali:</Typography>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                                <SportsEsportsIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                                <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Talk about Interests</b></Typography>
                                <Typography variant="body1">Discuss your hobbies, interests, or anything that's on your mind to get support and companionship.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                                <EmojiEventsIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                                <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Engage in Activities</b></Typography>
                                <Typography variant="body1">Play games, solve puzzles, or engage in interactive stories with your Pali to lift your mood.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                                <FeedbackIcon style={{ fontSize: 60, color: '#ff7f50ff' }} />
                                <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}><b>Share Your Thoughts</b></Typography>
                                <Typography variant="body1">Use your Pali as a sounding board to discuss ideas, dreams, or seek advice and feedback. No topic is off limits!</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

                <div style={{ marginTop: '40px', marginBottom: '100px' }}>
                    <Typography variant="h4" style={{ marginBottom: '50px', fontWeight: 'bold' }}><b>Frequently Asked Questions</b></Typography>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>What is a Pali?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                A Pali is your virtual friend, designed to engage in text-based conversations with you. You can personalize your Pali's appearance, personality, and interests!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>How does the conversation with my Pali work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Your Pali will communicate with you via text messages. Just like chatting with a friend, you can share thoughts, ask questions, and enjoy engaging conversations.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>Is my conversation with Pali private?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, your conversations with Pali are private. We prioritize your privacy and confidentiality in all interactions with your virtual friend.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography>Can I create multiple Palis?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, you can create multiple different friends! However, you can only have one active Pali at any given moment. You can switch your active Pali in the "Manage Friends" section by selecting the friend you would like to talk to and pressing "Set Active Friend".
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                        >
                            <Typography>Can I switch my active Pali?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, you can switch your active Pali in the “Manage Friends” Section.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6a-content"
                            id="panel6a-header"
                        >
                            <Typography>I created a Pali but want to change some things about them, is this possible?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, this is done in the Manage Friends section! Just click into your Pali and press the “Edit Information” button!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel7a-content"
                            id="panel7a-header"
                        >
                            <Typography>I want to change the Pali I am talking to, do I need a new number?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                No! All communication will happen on the same number. Also, if you change your Pali, any previous messages will still be remembered so you can pick right back off where you left!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <Container maxWidth={false} style={{
                    backgroundColor: 'black',
                    padding: '10px',
                    textAlign: 'center',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0
                }}>
                    <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                        © 2024 Dreamforge LLC
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={6} sm={3}>
                            <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </div>
    );
}

export default Tutorial;
