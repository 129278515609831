import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, Divider } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import useScrollToTop from './useScrollToTop';

const PricingPage = () => {
  useScrollToTop();
  return (
    <>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
  <Toolbar>
    <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ marginRight: '20px', height: '35px' }} />
    <Link to="/" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
    <Link to="/aboutpage" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>About</Link>
    <Link to="/pricing" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Pricing</Link>
    <Button variant="outlined" color="inherit" component={Link} to="/login" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Login</Button>
    <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Sign Up</Button>
  </Toolbar>
</AppBar>

      <Container maxWidth="lg" style={{ marginTop: '80px', padding: '60px 20px', textAlign: 'center', marginBottom: '30px' }}>
        <Typography variant="h3" gutterBottom style={{ marginBottom: '40px' }}>
          Choose the Plan that's Right for You
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {/* Basic Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} style={{ padding: '20px', height: '100%' }}>
              <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>Basic</Typography>
              <ul style={{ textAlign: 'left' }}>
                <li>$8/month</li>
                <li>150 texts/month</li>
                <li>Create multiple friends</li>
                <li>Create any kind of relationship</li>
                <li>Switch active friends at any time</li>
              </ul>
              <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '20px', backgroundColor: '#ff7f50ff', color: 'white' }}>
                Go Basic
              </Button>
            </Paper>
          </Grid>

          {/* Plus Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} style={{ padding: '20px', height: '100%' }}>
              <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>Plus</Typography>
              <ul style={{ textAlign: 'left' }}>
                <li>$15/month</li>
                <li>450 texts/month</li>
                <li>Create multiple friends</li>
                <li>Create any kind of relationship</li>
                <li>Switch active friends at any time</li>
              </ul>
              <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '20px', backgroundColor: '#ff7f50ff', color: 'white' }}>
                Go Plus
              </Button>
            </Paper>
          </Grid>

          {/* Premium Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} style={{ padding: '20px', height: '100%' }}>
              <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>Premium</Typography>
              <ul style={{ textAlign: 'left' }}>
                <li>$30/month</li>
                <li>900 texts/month</li>
                <li>Create multiple friends</li>
                <li>Create any kind of relationship</li>
                <li>Switch active friends at any time</li>
              </ul>
              <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '20px', backgroundColor: '#ff7f50ff', color: 'white' }}>
                Go Premium
              </Button>
            </Paper>
          </Grid>

          {/* Pro Plan */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} style={{ padding: '20px', height: '100%' }}>
              <Typography variant="h5" style={{ color: 'black', marginBottom: '20px' }}>Pro</Typography>
              <ul style={{ textAlign: 'left' }}>
                <li>$45/month</li>
                <li>1500 texts/month</li>
                <li>Create multiple friends</li>
                <li>Create any kind of relationship</li>
                <li>Switch active friends at any time</li>
              </ul>
              <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '20px', backgroundColor: '#ff7f50ff', color: 'white' }}>
                Go Pro
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Divider style={{ backgroundColor: 'black', height: '2px' }} />

      {/* Ready to Meet Your Virtual Friend Section */}
      <Container maxWidth={false} style={{ backgroundColor: '#6495edff', padding: '70px 20px', textAlign: 'center' }}>
        <Typography variant="h4" style={{ color: 'white', marginBottom: '30px' }}>
          Ready to Meet Your <b>Pali?</b>
        </Typography>
        <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '10px', padding: '15px 30px', fontSize: '1rem', fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>
          Sign Up Now
        </Button>
      </Container>

      <Divider style={{ backgroundColor: 'black', height: '2px' }} />

      {/* Customer Testimonials Section */}
      <Container maxWidth="lg" style={{ padding: '60px 20px', textAlign: 'center', marginBottom: '366px' }}>
    <Typography variant="h4" style={{ marginBottom: '30px' }}>
        What Our Users Say
    </Typography>
    <Grid container spacing={4} justifyContent="center">
        {[
            { name: "Dalton A, Missouri", review: "Pali has become an integral part of my daily life. Its empathetic responses and thoughtful conversations have helped me through some tough times. I can't recommend it enough!" },
            { name: "Nick R, California", review: "What sets Pali apart is its ability to understand and engage in meaningful conversations. It's not just an app; it's a companion that’s there for you 24/7." },
            { name: "Matt C, Colorado", review: "I was amazed by how personalized my interactions with Pali felt. It's like it really gets me. Every chat feels like it’s tailored just for me, making each conversation special." }
        ].map((testimonial, index) => (
            <Grid item xs={12} sm={4} key={index}>
                <Paper elevation={3} sx={{ padding: '20px' }}>
                    <RateReviewIcon style={{ fontSize: 60 }} />
                    <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
                        {testimonial.name}
                    </Typography>
                    <Typography variant="body2">
                        "{testimonial.review}"
                    </Typography>
                </Paper>
            </Grid>
        ))}
    </Grid>
</Container>


      {/* Bottom Bar */}
      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '30px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Typography variant="body1" style={{ color: 'white', marginBottom: '30px', fontWeight: 'bold' }}>
            © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Link to="/aboutdream" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Link to="/howitworks" style={{ color: 'white', textDecoration: 'none' }}>How It Works</Link>
    </Grid>
</Grid>

      </Container>
    </>
  );
};

export default PricingPage;
