import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, TextField } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';
import SchoolIcon from '@mui/icons-material/School';
import BookIcon from '@mui/icons-material/Book';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import LockIcon from '@mui/icons-material/Lock';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import SpaIcon from '@mui/icons-material/Spa';
import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import CakeIcon from '@mui/icons-material/Cake';
import GavelIcon from '@mui/icons-material/Gavel'; 
import LocalFloristIcon from '@mui/icons-material/LocalFlorist'; // For Girlfriend (if you decide to add it)



function EditRelationshipType() {
    const maxCharacters = 50;
    const [relationship, setRelationship] = useState('');
    const [customRelationship, setCustomRelationship] = useState('');
    const [charCount, setCharCount] = useState(maxCharacters);
    const [selectedTile, setSelectedTile] = useState('');
    const [hoveredTile, setHoveredTile] = useState(null);
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const auth = getAuth();
    useScrollToTop();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                getDoc(doc(db, "users", user.uid)).then(docSnap => {
                    if (docSnap.exists()) {
                        setUserName(docSnap.data().name);
                    }
                });
            } else {
                navigate("/login");
            }
        });
    }, [auth, db, navigate]);

    if (!location.state || !location.state.virtualFriendId) {
        alert("Invalid access. Redirecting to start.");
        navigate("/login");
        return null;
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const relationships = [
        { label: 'Girlfriend', description: 'Girlfriend', icon: <SpaIcon style={{ fontSize: 80 }} /> },
        { label: 'Boyfriend', description: 'Boyfriend', icon: <LocalCafeIcon style={{ fontSize: 80 }} /> },
        { label: 'Wife', description: 'Wife', icon: <LocalFloristIcon style={{ fontSize: 80 }} /> },
        { label: 'Husband', description: 'Husband', icon: <MoodIcon style={{ fontSize: 80 }} /> },
        { label: 'Partner', description: 'Partner', icon: <CakeIcon style={{ fontSize: 80 }} /> },
        { label: 'Friend', description: 'Friend.', icon: <PeopleIcon style={{ fontSize: 80 }} /> },
        { label: 'Mentor', description: 'Mentor', icon: <SchoolIcon style={{ fontSize: 80 }} /> },
        { label: 'Study Buddy', description: 'Study Buddy', icon: <BookIcon style={{ fontSize: 80 }} /> },
        { label: 'Debate Partner', description: 'Debate Partner', icon: <GavelIcon style={{ fontSize: 80 }} /> },
        { label: 'Coach', description: 'Coach', icon: <FitnessCenterIcon style={{ fontSize: 80 }} /> },
        { label: 'Confidant', description: 'Confidant', icon: <LockIcon style={{ fontSize: 80 }} /> },
        { label: 'Motivator', description: 'Motivator', icon: <SportsHandballIcon style={{ fontSize: 80 }} /> },
    ];
  

    const handleTileClick = (option) => {
        setRelationship(option.label);
        setSelectedTile(option.label);
    };

    const handleTileHover = (value) => {
        setHoveredTile(value);
    };

    const handleCustomRelationshipChange = (e) => {
        const input = e.target.value;
        setCustomRelationship(input);
        setCharCount(maxCharacters - input.length);
    };

    const handleSubmit = async () => {
        const relationshipToSave = customRelationship || (relationship && relationships.find(option => option.label === relationship).description);
        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { relationship: relationshipToSave }, { merge: true });
            navigate('/editpali', { state: { virtualFriendId } }); // Change this line
        } catch (error) {
            console.error("Error updating relationship type: ", error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
      <>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>

          <Container maxWidth="xl" style={{ marginTop: '100px', padding: '10px', textAlign: 'center', marginBottom: '80px' }}>
              <Typography variant="h4" gutterBottom style={{ marginBottom: '30px', fontWeight: 'bold', fontSize: '2.8rem' }}>
              {userName}, what kind of relationship are you looking to build with your Pali? <span style={{ fontSize: '2.2rem' }}><i>(choose one)</i></span>
              </Typography>
              <Grid container spacing={4} justifyContent="center">
                  {relationships.map((option, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                          <Paper 
                              style={{ 
                                  padding: '40px', 
                                  backgroundColor: selectedTile === option.label ? '#ff7f50ff' : 'black', 
                                  color: 'white',
                                  cursor: 'pointer',
                                  textAlign: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '150px',
                                  width: '320px',
                              }} 
                              elevation={3}
                              onClick={() => handleTileClick(option)}
                              onMouseOver={() => handleTileHover(option.label)}
                              onMouseOut={() => setHoveredTile(null)}
                          >
                              {hoveredTile === option.label || selectedTile === option.label ? (
                                  <Typography variant="subtitle1" style={{ color: 'white' }}>
                                      {option.description}
                                  </Typography>
                              ) : (
                                  <>
                                      {option.icon}
                                      <Typography variant="subtitle1" style={{ color: 'white', marginTop: '15px' }}>
                                          {option.label}
                                      </Typography>
                                  </>
                              )}
                          </Paper>
                      </Grid>
                  ))}
              </Grid>
              <TextField
                  label="Or enter a custom relationship"
                  type="text"
                  value={customRelationship}
                  onChange={handleCustomRelationshipChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: maxCharacters }}
              />
              <Typography variant="caption" display="block" gutterBottom>
                  {charCount} characters remaining
              </Typography>
              <div style={{ textAlign: 'left', margin: '20px 0', color: 'grey' }}>
              <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Tips for Creating Custom Relationships:
                    </Typography>
                <ul>
        <li>Examples of good custom inputs are: "Lover, Gym Buddy, Grandma, Math Tutor."</li>
        <li>Your Pali will read this input as "You are {userName}'s (custom relationship)", <b>so choose a custom input that fits in that sentence neatly to create the best Pali possible!</b></li>
        <li>Avoid specifying personality traits or detailed backstories here, as those will be developed in subsequent steps.</li>
    </ul>
                </div>
              <Button 
                  variant="contained" 
                  onClick={handleSubmit}
                  sx={{
                      margin: '20px 0',
                      height: '50px', 
                      width: '100%',
                      backgroundColor: 'black', 
                      color: 'white', 
                      '&:hover': { 
                          backgroundColor: '#ff7f50ff'
                      }
                  }}
              >
                  Save Changes
              </Button>
              <Button 
                  variant="outlined" 
                  onClick={goBack}
                  sx={{
                      margin: '20px 0',
                      height: '50px', 
                      width: '100%',
                      color: 'black', 
                      '&:hover': { 
                          backgroundColor: '#ff7f50ff'
                      }
                  }}
              >
                  Go Back
              </Button>
          </Container>

          <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
      <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
      </>
  );
}

export default EditRelationshipType;

