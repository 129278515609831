import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';



// Icon Imports
import ChatIcon from '@mui/icons-material/Chat';
import SchoolIcon from '@mui/icons-material/School';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'; // Updated for "Using humor and wit"
import SpaIcon from '@mui/icons-material/Spa';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BrushIcon from '@mui/icons-material/Brush';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WorkIcon from '@mui/icons-material/Work';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChildCareIcon from '@mui/icons-material/ChildCare'; // Updated for "Cute"
import MoodIcon from '@mui/icons-material/Mood';
import BuildIcon from '@mui/icons-material/Build';
import FlashOnIcon from '@mui/icons-material/FlashOn'; // Updated for "Energetic"
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'; // Updated for "Educational and informative"
// ... Import additional icons as needed

function EditCommStyle() {
    const [selectedStyle, setSelectedStyle] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const [userName, setUserName] = useState('');
    const auth = getAuth();
    useScrollToTop();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                getDoc(doc(db, "users", user.uid)).then(docSnap => {
                    if (docSnap.exists()) {
                        setUserName(docSnap.data().name);
                    }
                });
            } else {
                navigate("/login");
            }
        });
    }, [auth, db, navigate]);
    
    if (!location.state || !location.state.virtualFriendId) {
        alert("Invalid access. Redirecting to start.");
        navigate("/login");
        return null;
    }
    
    const handleLogout = () => {
        signOut(auth).then(() => {
          navigate('/login');
        }).catch((error) => {
          console.error('Logout Error:', error);
        });
      };


    const goBack = () => {
      navigate(-1); // This will navigate back to the previous page
  };

  const communicationOptions = [
    {
        description: "With an upbeat and energetic tone, keeping things lively and fun.",
        icon: <FlashOnIcon style={{ fontSize: 80 }} />,
        value: "Energetic"
    },
    {
        description: "In a thoughtful and reflective manner, encouraging deep conversations.",
        icon: <SchoolIcon style={{ fontSize: 80 }} />,
        value: "Thoughtful"
    },
    {
        description: "Using humor and wit, making sure there's never a dull moment.",
        icon: <SentimentVerySatisfiedIcon style={{ fontSize: 80 }} />,
        value: "Humorous"
    },
    {
        description: "Calmly and soothingly, providing a sense of peace and relaxation.",
        icon: <SpaIcon style={{ fontSize: 80 }} />,
        value: "Calm"
    },
    {
        description: "Direct and to the point, focusing on clear and concise communication.",
        icon: <RecordVoiceOverIcon style={{ fontSize: 80 }} />,
        value: "Direct"
    },
    {
        description: "In a supportive and affirming way, always there to boost your confidence.",
        icon: <ThumbUpAltIcon style={{ fontSize: 80 }} />,
        value: "Supportive"
    },
    {
        description: "With curiosity and inquisitiveness, always asking questions to learn more about you.",
        icon: <LiveHelpIcon style={{ fontSize: 80 }} />,
        value: "Curious"
    },
    {
        description: "Using motivational and inspirational dialogue, helping to push you towards your goals.",
        icon: <EmojiObjectsIcon style={{ fontSize: 80 }} />,
        value: "Motivational"
    },
    {
        description: "Expressively and artistically, finding creative ways to communicate ideas.",
        icon: <BrushIcon style={{ fontSize: 80 }} />,
        value: "Artistic"
    },
    {
        description: "In a logical and analytical manner, focusing on facts and reasoning.",
        icon: <LightbulbIcon style={{ fontSize: 80 }} />,
        value: "Logical"
    },
    {
        description: "Casually, with a relaxed and friendly approach to conversation.",
        icon: <ChatIcon style={{ fontSize: 80 }} />,
        value: "Casual"
    },
    {
        description: "Professionally, with a focus on clarity, respect, and a formal tone.",
        icon: <WorkIcon style={{ fontSize: 80 }} />,
        value: "Professional"
    },
    {
        description: "With love and affection, showing warmth and care in your interactions.",
        icon: <FavoriteIcon style={{ fontSize: 80 }} />,
        value: "Loving"
    },
    {
        description: "In a cute manner, using endearing language and expressions.",
        icon: <ChildCareIcon style={{ fontSize: 80 }} />,
        value: "Cute"
    },
    {
        description: "Sarcastically and playfully, adding a layer of humor to keep the mood light.",
        icon: <MoodIcon style={{ fontSize: 80 }} />,
        value: "Sarcastic"
    },
    {
        description: "Practically and with a focus on solutions, aiming to provide actionable advice.",
        icon: <BuildIcon style={{ fontSize: 80 }} />,
        value: "Practical"
    },
    {
        description: "Educationally, sharing knowledge and information to enrich understanding.",
        icon: <LocalLibraryIcon style={{ fontSize: 80 }} />,
        value: "Educational"
    },
];

    const handleTileClick = (optionLabel) => {
        setSelectedStyle(optionLabel);
    };

    const handleSubmit = async () => {
        if (!selectedStyle) {
            alert('Please select a communication style.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { communicationStyle: selectedStyle }, { merge: true });
            navigate('/editpali', { state: { virtualFriendId } }); // Change this line
        } catch (error) {
            console.error("Error updating communication style: ", error);
        }
    };

    // AppBar component
    return (
        <>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>
            {/* Main content container */}
            <Container maxWidth="xl" style={{ marginTop: '120px', padding: '10px', textAlign: 'center', marginBottom: '200px' }}>
            <Typography variant="h4" gutterBottom style={{ marginBottom: '60px', fontWeight: 'bold', fontSize: '2.8rem' }}>
                    How would you like your Pali to communicate with you?
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {communicationOptions.map((option, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Paper 
                                style={{ 
                                    padding: '40px', 
                                    backgroundColor: selectedStyle === option.value ? '#ff7f50ff' : 'black', 
                                    color: 'white',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '150px',
                                    width: '320px',
                                }} 
                                elevation={3}
                                onClick={() => handleTileClick(option.value)}
                            >
                                {option.icon}
                                <Typography variant="subtitle1" style={{ color: 'white', marginTop: '15px' }}>
                                    {option.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>

                <Button 
                    variant="contained" 
                    onClick={handleSubmit}
                    sx={{
                        margin: '20px 0',
                        marginTop: '50px',
                        height: '50px', 
                        width: '100%',
                        backgroundColor: 'black', 
                        color: 'white', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Save Changes
                </Button>
                <Button 
                        variant="outlined"
                        onClick={goBack}
                        sx={{
                            height: '50px',
                            width: '100%',
                            borderColor: 'black',
                            color: 'black', 
                            '&:hover': { 
                                backgroundColor: '#ff7f50ff'
                            }
                        }}
                    >
                        Go Back
                    </Button>
            </Container>

            {/* Footer container */}
            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
      <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
        </>
    );
}

export default EditCommStyle;
