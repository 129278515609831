import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, getFirestore } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ExploreIcon from '@mui/icons-material/Explore';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BrushIcon from '@mui/icons-material/Brush';
import BuildIcon from '@mui/icons-material/Build';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GroupsIcon from '@mui/icons-material/Groups';
import SpaIcon from '@mui/icons-material/Spa';
import TimerIcon from '@mui/icons-material/Timer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SecurityIcon from '@mui/icons-material/Security';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function PersonalityTraits1() {
    const maxTraits = 1;
    const [traits, setTraits] = useState([]);
    const [hoveredTile, setHoveredTile] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [userName, setUserName] = useState('');
    const db = getFirestore();
    const auth = getAuth();
    const [fromSummary, setFromSummary] = useState(false);
    const [virtualFriendName, setVirtualFriendName] = useState('');
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                }

                if (location.state?.virtualFriendId) {
                    const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', location.state.virtualFriendId);
                    const virtualFriendSnap = await getDoc(virtualFriendRef);
                    if (virtualFriendSnap.exists()) {
                        setVirtualFriendName(virtualFriendSnap.data().name);
                        if (location.state.fromSummary) {
                            setFromSummary(true);
                            setTraits(virtualFriendSnap.data().traits || []);
                        }
                    }
                } else {
                    alert("Invalid access. Redirecting to start.");
                    navigate("/start");
                }
            } else {
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [auth, db, navigate, location]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const personalityTraits = [
        { label: "Cheerful and optimistic, always looking on the bright side.", icon: <EmojiEmotionsIcon style={{ fontSize: 80 }} />, description: "Cheerful and optimistic, always looking on the bright side." },
        { label: "Intellectual and curious, loves learning and discussing ideas.", icon: <LightbulbIcon style={{ fontSize: 80 }} />, description: "Intellectual and curious, loves learning and discussing ideas." },
        { label: "Empathetic and supportive, always there to listen.", icon: <FavoriteIcon style={{ fontSize: 80 }} />, description: "Empathetic and supportive, always there to listen." },
        { label: "Witty and humorous, able to lighten the mood with a good laugh.", icon: <SentimentVerySatisfiedIcon style={{ fontSize: 80 }} />, description: "Witty and humorous, able to lighten the mood with a good laugh." },
        { label: "Adventurous and spontaneous, always up for trying new things.", icon: <ExploreIcon style={{ fontSize: 80 }} />, description: "Adventurous and spontaneous, always up for trying new things." },
        { label: "Calm and collected, providing a sense of stability in stressful times.", icon: <AcUnitIcon style={{ fontSize: 80 }} />, description: "Calm and collected, providing a sense of stability in stressful times." },
        { label: "Creative and imaginative, always ready to brainstorm and dream up ideas.", icon: <BrushIcon style={{ fontSize: 80 }} />, description: "Creative and imaginative, always ready to brainstorm and dream up ideas." },
        { label: "Practical and organized, helping to keep people focused and on track.", icon: <BuildIcon style={{ fontSize: 80 }} />, description: "Practical and organized, helping to keep people focused and on track." },
        { label: "Resilient and determined, overcoming challenges with strength and determination.", icon: <FitnessCenterIcon style={{ fontSize: 80 }} />, description: "Resilient and determined, overcoming challenges with strength and determination." },
        { label: "Reflective and philosophical, thinking deeply about life's big questions.", icon: <MenuBookIcon style={{ fontSize: 80 }} />, description: "Reflective and philosophical, thinking deeply about life's big questions." },
        { label: "Ambitious and driven, focused on setting and achieving goals.", icon: <TrendingUpIcon style={{ fontSize: 80 }} />, description: "Ambitious and driven, focused on setting and achieving goals." },
        { label: "Sociable and charismatic, enjoys engaging with people and making friends.", icon: <GroupsIcon style={{ fontSize: 80 }} />, description: "Sociable and charismatic, enjoys engaging with people and making friends." },
        { label: "Mindful and present, living in the moment and practicing mindfulness.", icon: <SpaIcon style={{ fontSize: 80 }} />, description: "Mindful and present, living in the moment and practicing mindfulness." },
        { label: "Innovative and visionary, seeing beyond the present to imagine future possibilities.", icon: <LightbulbIcon style={{ fontSize: 80 }} />, description: "Innovative and visionary, seeing beyond the present to imagine future possibilities." },
        { label: "Disciplined and focused.", icon: <TimerIcon style={{ fontSize: 80 }} />, description: "Disciplined and focused." },
        { label: "Compassionate and caring, putting others' needs first with a caring heart.", icon: <FavoriteBorderIcon style={{ fontSize: 80 }} />, description: "Compassionate and caring, putting others' needs first with a caring heart." },
        { label: "Confident and assertive, radiating confidence and standing up for what you believe in.", icon: <SecurityIcon style={{ fontSize: 80 }} />, description: "Confident and assertive, radiating confidence and standing up for what you believe in." },
        { label: "Loyal and dependable, a reliable friend you can always count on.", icon: <LoyaltyIcon style={{ fontSize: 80 }} />, description: "Loyal and dependable, a reliable friend you can always count on." },
        { label: "Shy and reserved, preferring listening over talking and enjoys solitude.", icon: <VisibilityOffIcon style={{ fontSize: 80 }} />, description: "Shy and reserved, preferring listening over talking and enjoys solitude." }
    ];

    const handleSelection = (traitLabel) => {
        setTraits([traitLabel]);
    };

    const handleSaveChanges = async () => {
        if (traits.length === 0) {
            alert('Please select at least one personality trait.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await updateDoc(virtualFriendRef, { traits });
            navigate('/summary', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating personality traits: ", error);
        }
    };

    const handleSubmit = async () => {
        if (traits.length === 0) {
            alert('Please select at least one personality trait.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { traits }, { merge: true });
            navigate('/interests1', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating personality traits: ", error);
        }
    };

    const goBack = () => {
        if (fromSummary) {
            navigate('/summary', { state: { virtualFriendId: location.state.virtualFriendId } });
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container maxWidth="xl" style={{ marginTop: '100px', padding: '20px', textAlign: 'center', marginBottom: '285px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '30px', fontWeight: 'bold', fontSize: '2.8rem', marginBottom: '50px' }}>
                    What kind of personality does {virtualFriendName} have? <span style={{ fontSize: '2.2rem' }}><i>(choose one)</i></span>
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {personalityTraits.map((trait, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Paper 
                                style={{ 
                                    padding: '40px', 
                                    backgroundColor: traits.includes(trait.label) ? '#ff7f50ff' : 'black', 
                                    color: 'white', 
                                    cursor: 'pointer', 
                                    textAlign: 'center', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    height: '150px',
                                    width: '320px',
                                    transition: 'transform 0.3s ease',
                                    transform: hoveredTile === trait.label || traits.includes(trait.label) ? 'scale(1.05)' : 'scale(1)',
                                }} 
                                elevation={3}
                                onClick={() => handleSelection(trait.label)}
                                onMouseOver={() => setHoveredTile(trait.label)}
                                onMouseOut={() => setHoveredTile(null)}
                            >
                                {trait.icon}
                                <Typography variant="subtitle1" style={{ color: 'white', marginTop: '15px' }}>
                                    {trait.label}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <Button 
                    variant="contained" 
                    onClick={fromSummary ? handleSaveChanges : handleSubmit}
                    sx={{
                        margin: '20px 0',
                        marginTop: '90px',
                        height: '50px', 
                        width: '100%',
                        backgroundColor: 'black', 
                        color: 'white', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    {fromSummary ? 'Save Changes' : 'Next Step'}
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={goBack}
                    sx={{
                        margin: '20px 0',
                        height: '50px', 
                        width: '100%',
                        color: 'black', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default PersonalityTraits1;
