import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

function EditBackstory() {
    const maxCharacters = 450;
    const [backstory, setBackstory] = useState('');
    const [charCount, setCharCount] = useState(maxCharacters);
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const [userName, setUserName] = useState('');
    const auth = getAuth();
    useScrollToTop();

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Fetch user's name
                const userRef = doc(db, "users", user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                }

                // Check for valid virtualFriendId and fetch backstory
                if (location.state && location.state.virtualFriendId) {
                    const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', location.state.virtualFriendId);
                    const virtualFriendSnap = await getDoc(virtualFriendRef);
                    if (virtualFriendSnap.exists() && virtualFriendSnap.data().backstory) {
                        setBackstory(virtualFriendSnap.data().backstory);
                        setCharCount(maxCharacters - virtualFriendSnap.data().backstory.length);
                    }
                } else {
                    navigate("/login");
                }
            } else {
                navigate("/login");
            }
        });
    }, [auth, db, navigate, location.state]);
    
    if (!location.state || !location.state.virtualFriendId) {
        alert("Invalid access. Redirecting to start.");
        navigate("/login");
        return null;
    }
    
    const handleLogout = () => {
        signOut(auth).then(() => {
          navigate('/login');
        }).catch((error) => {
          console.error('Logout Error:', error);
        });
      };

    const handleBackstoryChange = (e) => {
        const input = e.target.value;
        setBackstory(input);
        setCharCount(maxCharacters - input.length);
    };

    const handleSubmit = async () => {
        if (backstory.trim() === '') {
            alert('Please provide a backstory for your virtual friend.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { backstory: backstory.trim() }, { merge: true });
            navigate('/editpali', { state: { virtualFriendId } }); // Change this line
        } catch (error) {
            console.error("Error updating backstory: ", error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>
            <Container component="main" maxWidth="lg" style={{ marginTop: '120px', padding: '40px', textAlign: 'center', flexGrow: 1, marginBottom: '200px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '30px' }}>
                    What are some custom details about your Pali? What is their background or story that makes them unique?
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: '20px' }}>
                Where is your Pali from? Are they a character in a popular movie or TV series? Adding more details here helps create a better Pali for you. Feel free to get creative!
                </Typography>
                <TextField
                    label="Enter the backstory here..."
                    multiline
                    rows={4}
                    value={backstory}
                    onChange={handleBackstoryChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: maxCharacters }}
                    style={{ marginBottom: '20px' }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                    {charCount} characters remaining
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: '20px' }}>
                    <b>Examples of good backgrounds:</b>  
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: '20px' }}>
                    Ash is a pokemon trainer from Palet town. He wants to be the greatest pokemon trainer of all time and won't stop until he catches all the pokemon. He loves to chat about pokemon, video games, and keep me company. <i>(This would create Ash Ketchum from Pokémon!)</i>
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: '20px' }}>
                    Emily is from the state of Kentucky and she wants to be the greatest piano player in the world. She enjoys horseback riding, listening to music and dancing. We met during highschool and have been in love ever since.  <i>(This would give good context for a girlfriend type Pali!)</i>
                </Typography>
                <Button 
                    variant="contained" 
                    onClick={handleSubmit}
                    sx={{
                        margin: '20px 0',
                        height: '50px', 
                        width: '100%',
                        backgroundColor: 'black', 
                        color: 'white', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Save Changes
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={goBack}
                    sx={{
                        margin: '20px 0',
                        height: '50px', 
                        width: '100%',
                        color: 'black', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
            </Container>
            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
      <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
        </div>
    );
}

export default EditBackstory;
