import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, Divider } from '@mui/material';
import Diversity1TwoToneIcon from '@mui/icons-material/Diversity1TwoTone'; // Ensure this import is at the top of your file
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

const HomePage = () => {

    const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Fnew%20app%20home%20page.png?alt=media';

    return (
        <>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ marginRight: '20px', height: '35px' }} />
                    <Link to="/" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/aboutpage" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>About</Link>
                    <Link to="/pricing" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Pricing</Link>
                    <Button variant="outlined" color="inherit" component={Link} to="/login" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Login</Button>
                    <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Sign Up</Button>
                </Toolbar>
            </AppBar>

            <Container maxWidth={false} style={{ backgroundColor: '#eeebea', padding: '70px 20px', textAlign: 'center', marginTop: '60px' }}>
                <Typography variant="h3" gutterBottom style={{ color: 'black', marginBottom: '30px', fontSize: '3.7rem', color: '#ff7f50ff', marginTop: '20px' }}>
                    <b>Pali</b> - Conversations That Matter, Anytime You Need
                </Typography>
                <Typography variant="h5" gutterBottom style={{ color: 'black', marginBottom: '60px', fontSize: '1.8rem' }}>
                    Create your own personalized companion that is there for you 24/7. Chat via text message anytime, anywhere, no apps required.
                </Typography>
                <Button variant="contained" component={Link} to="/signup" sx={{ margin: '10px', padding: '15px 30px', fontSize: '1.2rem', backgroundColor: 'black', color: 'white', fontWeight: 'bold', '&:hover': { backgroundColor: '#ff7f50ff', color: 'white' } }}>Sign Up</Button>
                <Button variant="contained" component={Link} to="/login" sx={{ margin: '10px', padding: '15px 30px', fontSize: '1.2rem', backgroundColor: 'black', color: 'white', fontWeight: 'bold', '&:hover': { backgroundColor: '#ff7f50ff', color: 'white' } }}>Login</Button>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            <Container maxWidth="lg" style={{ backgroundColor: '#ffffff', padding: '60px 20px', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="h4" style={{ color: 'black', marginBottom: '30px', fontWeight: 'bold', fontSize: '2.5rem' }}>
                    Emotional Support in Your Pocket
                </Typography>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12} style={{ textAlign: 'center', marginTop: '10px' }}>
                        <img src={imageUrl} alt="Pali App Home" style={{ maxWidth: '90%', maxHeight: '700px', height: 'auto', margin: '0 auto', display: 'block' }} />
                        <Typography style={{ color: 'black', fontSize: '1.4rem', marginBottom: '20px', marginTop: '30px' }}>
                            From deep conversations to supportive chats, create a friend that aligns with your needs. Pali is more than an app; it's a companion for mental wellness, offering comfort, advice, and friendship through every text.
                        </Typography>
                        <Typography style={{ color: 'black', fontSize: '1.4rem', marginBottom: '20px', marginTop: '30px' }}>
                            All interactions take place via standard text messages, allowing you to connect with your Pali anytime, anywhere, without the need for additional apps.
                        </Typography>
                        <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '10px', padding: '15px 30px', fontSize: '1.2rem', fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff', color: 'white' } }}>Get Started</Button>
                    </Grid>
                </Grid>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            <Container maxWidth="lg" style={{ backgroundColor: '#ffffff', padding: '60px 20px', margin: '0 auto', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="h4" style={{ textAlign: 'center', color: 'black', marginBottom: '30px', fontWeight: 'bold', fontSize: '2.5rem' }}>
                    How Pali Supports You:
                </Typography>
                <Typography variant="body1" style={{ textAlign: 'center', color: 'black', marginBottom: '40px', fontSize: '1.4rem' }}>
                    Design your supportive friend in just a few easy steps! Customize a companion that provides comfort, advice, and emotional support whenever you need it.
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={4}>
                        <Paper style={{ padding: '20px', textAlign: 'center', backgroundColor: 'white' }} elevation={0}>
                            <PlayCircleFilledOutlinedIcon style={{ fontSize: 100, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ color: 'black', marginTop: '15px' }}>
                                Create Account
                            </Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Sign up to get started.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper style={{ padding: '20px', textAlign: 'center', backgroundColor: 'white' }} elevation={0}>
                            <SaveAsOutlinedIcon style={{ fontSize: 100, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ color: 'black', marginTop: '15px' }}>
                                Customize Your Pali
                            </Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Create an extremely customizable digital companion.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper style={{ padding: '20px', textAlign: 'center', backgroundColor: 'white' }} elevation={0}>
                            <ForumOutlinedIcon style={{ fontSize: 100, color: '#ff7f50ff' }} />
                            <Typography variant="h6" style={{ color: 'black', marginTop: '15px' }}>
                                Start Chatting With Your Pali
                            </Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Talk with your Pali via text message instantly.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            <Container maxWidth={false} style={{ backgroundColor: '#6495edff', padding: '100px 20px', margin: '0 auto', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="h4" style={{ color: 'black', marginBottom: '30px', fontWeight: 'bold', fontSize: '2.5rem' }}>
                    Always Have Someone to Talk to
                </Typography>
                <Typography variant="body1" style={{ color: 'black', marginBottom: '20px', fontWeight: 'bold', fontSize: '1.3rem' }}>
                    Create your own Pali and start your journey to better mental well-being today.
                </Typography>
                <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '10px', padding: '20px 40px', fontSize: '1.5rem', fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff', color: 'white' } }}>Get Started</Button>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '30px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '30px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/aboutdream" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/howitworks" style={{ color: 'white', textDecoration: 'none' }}>How It Works</Link>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default HomePage;
