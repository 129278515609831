import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Paper, Grid } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

const EULAPage = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const [userName, setUserName] = useState('');
  const location = useLocation();
  useScrollToTop();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            getDoc(doc(db, "users", user.uid)).then(docSnap => {
                if (docSnap.exists()) {
                    setUserName(docSnap.data().name);
                }
            });
        } else {
            navigate("/login");
        }
    });
}, [auth, db, navigate]);

const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>

      <Container component="main" maxWidth="md" style={{ flex: 1, marginTop: '120px', padding: '40px', textAlign: 'center', marginBottom: '20px' }}>
        <Typography variant="h4" gutterBottom style={{ marginBottom: '30px' }}>
          End-User License Agreement (EULA)
        </Typography>
        <Paper elevation={3} style={{ padding: '20px', minHeight: '500px' }}>
          {/* Placeholder for EULA */}
          <Typography variant="body1" style={{ color: 'black' }}>
          End-User License Agreement (EULA) of Pali
This End-User License Agreement ("EULA") is a legal agreement between you and DreamForge LLC . Our EULA was created by EULA Template for Pali.

This EULA agreement governs your acquisition and use of our Pali software ("Software") directly from DreamForge LLC or indirectly through a DreamForge LLC authorized reseller or distributor (a "Reseller").

Please read this EULA agreement carefully before completing the installation process and using the Pali software. It provides a license to use the Pali software and contains warranty information and liability disclaimers.

If you register for a free trial of the Pali software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the Pali software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.

If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.

This EULA agreement shall apply only to the Software supplied by DreamForge LLC herewith regardless of whether other software is referred to or described herein. The terms also apply to any DreamForge LLC updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.

License Grant
DreamForge LLC hereby grants you a personal, non-transferable, non-exclusive licence to use the Pali software on your devices in accordance with the terms of this EULA agreement.

You are permitted to load the Pali software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the Pali software.

You are not permitted to:

Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things
Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose
Allow any third party to use the Software on behalf of or for the benefit of any third party
Use the Software in any way which breaches any applicable local, national or international law
use the Software for any purpose that DreamForge LLC considers is a breach of this EULA agreement
Intellectual Property and Ownership
DreamForge LLC shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of DreamForge LLC .

DreamForge LLC reserves the right to grant licences to use the Software to third parties.

Termination
This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to DreamForge LLC .

It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.

Governing Law
This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of us.
          </Typography>
        </Paper>
        <Button onClick={() => navigate('/settings')} variant="contained" sx={{ marginTop: '20px', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>
          Back to User Settings
        </Button>
      </Container>

      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EULAPage;
