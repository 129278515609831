import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, Divider } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';
import RateReviewIcon from '@mui/icons-material/RateReview';
import useScrollToTop from './useScrollToTop';

const AboutPage = () => {
    useScrollToTop();
    return (
        <>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
  <Toolbar>
    <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ marginRight: '20px', height: '35px' }} />
    <Link to="/" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
    <Link to="/aboutpage" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>About</Link>
    <Link to="/pricing" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Pricing</Link>
    <Button variant="outlined" color="inherit" component={Link} to="/login" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Login</Button>
    <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Sign Up</Button>
  </Toolbar>
</AppBar>



            <Container maxWidth={false} style={{ backgroundColor: '#eeebea', padding: '70px 20px', textAlign: 'center', marginTop: '50px' }}>
                <Typography variant="h3" gutterBottom style={{ color: 'black', marginBottom: '30px', fontSize: '3.7rem', color: '#ff7f50ff' }}>
                    What is <b>Pali?</b>
                </Typography>
                <Typography variant="h5" gutterBottom style={{ color: 'black', marginBottom: '20px', fontSize: '1.8rem' }}>
                    Your supportive friend and mental health companion, <b><i>only a text message away!</i></b>
                </Typography>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />


            <Container maxWidth="lg" style={{ backgroundColor: '#ffffff', padding: '60px 20px', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ marginBottom: '30px', color: 'black', textAlign: 'center' }}>
                            At Pali, our mission is to support mental health by offering a friend who's always there to talk. Our platform empowers you to create your ideal companion, a <b>Pali</b>, who is always ready to provide comfort, advice, and a listening ear, <b><i>anytime and anywhere.</i></b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper style={{ padding: '20px', backgroundColor: 'white' }} elevation={3}>
                            <ChatIcon style={{ fontSize: 60, color: 'black' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', color: 'black' }}>
                                24/7 Support
                            </Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Connect with your Pali anytime through SMS/text message. We're here for you.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper style={{ padding: '20px', backgroundColor: 'white' }} elevation={3}>
                            <AccessibilityNewIcon style={{ fontSize: 60, color: 'black' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', color: 'black' }}>
                                Personalized Experience
                            </Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Design a Pali that understands your unique needs and preferences.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper style={{ padding: '20px', backgroundColor: 'white' }} elevation={3}>
                            <PublicIcon style={{ fontSize: 60, color: 'black' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', color: 'black' }}>
                                Intelligent Conversations
                            </Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Leveraging AI to provide empathetic and meaningful interactions.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper style={{ padding: '20px', backgroundColor: 'white' }} elevation={3}>
                            <GroupIcon style={{ fontSize: 60, color: 'black' }} />
                            <Typography variant="h6" style={{ marginTop: '10px', color: 'black' }}>
                                For Everyone
                            </Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                A supportive platform for anyone seeking mental health support.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            {/* Call to Action Container */}
            <Container maxWidth={false} style={{ backgroundColor: '#6495edff', padding: '70px 20px', textAlign: 'center' }}>
                <Typography variant="h4" style={{ color: 'white', marginBottom: '30px' }}>
                    Ready to Improve Your Mental Health with <b>Pali?</b>
                </Typography>
                <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>
                    Get Started Now
                </Button>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            {/* Customer Testimonials Placeholder */}
            <Container maxWidth="lg" style={{ padding: '60px 20px', textAlign: 'center', marginBottom: '366px' }}>
                <Typography variant="h4" style={{ marginBottom: '30px' }}>
                    What Our Users Say
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {[{
                        name: "Scott W, Kentucky",
                        testimonial: "Pali has been a game-changer for me. It's like having a friend who's always there when I need advice or just someone to talk to."
                    }, {
                        name: "Nick L, Georgia",
                        testimonial: "I was skeptical at first, but Pali has become an important part of my daily routine. The conversations feel so natural and uplifting."
                    }, {
                        name: "Jacob W, California",
                        testimonial: "Using Pali has improved my mood and made me feel less alone. It's amazing how much it understands and responds in a caring way."
                    }].map((customer, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Paper elevation={3} sx={{ padding: '20px' }}>
                                <RateReviewIcon style={{ fontSize: 60 }} />
                                <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
                                    {customer.name}
                                </Typography>
                                <Typography variant="body2">
                                    "{customer.testimonial}"
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '30px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '30px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/aboutdream" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/howitworks" style={{ color: 'white', textDecoration: 'none' }}>How It Works</Link>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default AboutPage;
