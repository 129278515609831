import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid } from '@mui/material';
import useScrollToTop from './useScrollToTop';
import { auth } from './Firebase';

function Login() {
  useScrollToTop();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in:', userCredential.user);
      navigate('/account');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
  <Toolbar>
    <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ marginRight: '20px', height: '35px' }} />
    <Link to="/" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
    <Link to="/aboutpage" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>About</Link>
    <Link to="/pricing" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Pricing</Link>
    <Button variant="outlined" color="inherit" component={Link} to="/login" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Login</Button>
    <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Sign Up</Button>
  </Toolbar>
</AppBar>

      <Container component="main" maxWidth="sm" style={{ marginTop: '100px', padding: '20px', textAlign: 'center', flexGrow: 1, paddingBottom: '100px' }}>
        <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
          Welcome Back, Login to Your Account
        </Typography>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            sx={{
              marginBottom: '20px', 
              width: '100%', 
              backgroundColor: 'black',
              color: 'white', 
              '&:hover': { 
                backgroundColor: '#ff7f50ff'
              }
            }}
          >
            Login
          </Button>
        </form>
        <Button 
        variant="outlined" 
        onClick={() => navigate('/passwordreset')} 
        sx={{ 
          width: '100%', 
          color: 'black', 
          borderColor: 'black',
          '&:hover': { 
            backgroundColor: '#ff7f50ff'
          },
          marginTop: '10px'
        }}
      >
        Password Reset
      </Button>
      <Button 
        variant="outlined" 
        onClick={() => navigate('/')} 
        sx={{ 
          width: '100%', 
          color: 'black', 
          borderColor: 'black',
          '&:hover': { 
            backgroundColor: '#ff7f50ff'
          },
          marginTop: '10px'
        }}
      >
        Go Back
      </Button>
    </Container>

    <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '30px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
            <Typography variant="body1" style={{ color: 'white', marginBottom: '30px', fontWeight: 'bold' }}>
                © 2024 Dreamforge LLC
            </Typography>
            <Grid container justifyContent="center">
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Link to="/aboutdream" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Link to="/howitworks" style={{ color: 'white', textDecoration: 'none' }}>How It Works</Link>
    </Grid>
</Grid>

        </Container>
  </div>
);
}

export default Login;
