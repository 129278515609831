import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection } from 'firebase/firestore';
import { db } from './Firebase';
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid, Paper } from '@mui/material';
import useScrollToTop from './useScrollToTop';

function SignUp() {
    useScrollToTop();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();
    const auth = getAuth(); 

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(collection(db, 'users'), user.uid), {
                email: user.email,
                // Additional fields can be added here
            });

            console.log('User account and Firestore document created.');
            navigate('/onboard');
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ marginRight: '20px', height: '35px' }} />
                    <Link to="/" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/aboutpage" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>About</Link>
                    <Link to="/pricing" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Pricing</Link>
                    <Button variant="outlined" color="inherit" component={Link} to="/login" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Login</Button>
                    <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Sign Up</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="sm" style={{ marginTop: '100px', padding: '20px', textAlign: 'center', flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
                    Your Pali is Waiting. Create an Account to get started.
                </Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit" 
                        sx={{
                            marginBottom: '20px', 
                            width: '100%', 
                            backgroundColor: 'black',
                            color: 'white', 
                            '&:hover': { 
                                backgroundColor: '#ff7f50ff'
                            }
                        }}
                    >
                        Sign Up
                    </Button>
                </form>
                <Button 
                    variant="outlined" 
                    onClick={() => navigate('/')} 
                    sx={{ 
                        width: '100%', 
                        color: 'black',
                        marginBottom: '20px',
                        borderColor: 'black',
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
            </Container>

            <Container maxWidth="md" style={{ marginTop: '10px', marginBottom: '400px', textAlign: 'center' }}>
                <Typography variant="h5" style={{ marginBottom: '30px', color: 'black' }}>
                    <b>Fun Things to Do with Your Pali</b>
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Play Games</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Engage in fun text-based games like trivia, 20 questions, and storytelling adventures.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Share Stories</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Tell your Pali about your day, your dreams, and your aspirations. It's like having a diary that responds!
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Get Advice</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Seek guidance and advice on various topics, whether it's about stress, new hobbies, or more!
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Learn New Things</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Explore new topics and expand your knowledge with your Pali as your study buddy.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Practice Mindfulness</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Engage in mindfulness exercises and stress-relief techniques guided by your Pali.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Set Goals</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Work with your Pali to set and track personal goals and celebrate together.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '30px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '30px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/aboutdream" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/howitworks" style={{ color: 'white', textDecoration: 'none' }}>How It Works</Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default SignUp;
