import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, collection, addDoc, updateDoc, getFirestore } from "firebase/firestore";
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid } from '@mui/material';
import useScrollToTop from './useScrollToTop';

function AICustomization() {
    const [name, setName] = useState('');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const auth = getAuth();
    const [fromSummary, setFromSummary] = useState(false);
    const [virtualFriendId, setVirtualFriendId] = useState(null);
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                }

                // Check if the component should be in 'edit' mode
                if (location.state && location.state.fromSummary) {
                    setFromSummary(true);
                    setVirtualFriendId(location.state.virtualFriendId);
                }
            } else {
                navigate('/login');
            }
        });
        return unsubscribe;
    }, [auth, db, navigate, location]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleSaveChanges = async () => {
        if (name === '') {
            alert('Please enter a name for your virtual friend.');
            return;
        }

        // Logic to update the virtual friend's name
        if (fromSummary && virtualFriendId) {
            const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);
            try {
                await updateDoc(virtualFriendRef, { name });
                navigate('/summary', { state: { virtualFriendId } });
            } catch (error) {
                console.error("Error updating virtual friend's name: ", error);
            }
        } else {
            // Handle the creation of a new virtual friend
            try {
                const virtualFriendRef = await addDoc(collection(db, 'users', auth.currentUser.uid, 'virtualFriends'), { name });
                navigate('/relationshipstatus', { state: { virtualFriendId: virtualFriendRef.id } });
            } catch (error) {
                console.error("Error creating virtual friend: ", error);
            }
        }
    };

    const handleSubmit = async () => {
        if (name === '') {
            alert('Please enter a name for your virtual friend.');
            return;
        }

        try {
            // This is the logic for creating a new virtual friend
            const virtualFriendRef = await addDoc(collection(db, 'users', auth.currentUser.uid, 'virtualFriends'), { name });
            navigate('/relationshipstatus', { state: { virtualFriendId: virtualFriendRef.id } });
        } catch (error) {
            console.error("Error creating virtual friend: ", error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="sm" style={{ flex: 1, marginTop: '100px', padding: '20px', textAlign: 'center', paddingBottom: '150px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
                    Nice to meet you, {userName}! Let's begin crafting your perfect companion.
                </Typography>
                <Typography variant="h5" gutterBottom style={{ marginBottom: '20px' }}>
                   What is the name of your companion?
                </Typography>
                <TextField
                    label="Enter Name"
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <Button variant="contained" onClick={fromSummary ? handleSaveChanges : handleSubmit} sx={{ margin: '20px 0', width: '100%', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>
                    {fromSummary ? 'Save Changes' : 'Next'}
                </Button>
                <Button variant="outlined" onClick={goBack} sx={{ width: '100%', color: 'black', borderColor: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}>
                    Go Back
                </Button>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default AICustomization;
