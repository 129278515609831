import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, TextField } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, getFirestore } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ComputerIcon from '@mui/icons-material/Computer';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import BookIcon from '@mui/icons-material/Book';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import SchoolIcon from '@mui/icons-material/School';
import GavelIcon from '@mui/icons-material/Gavel';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import BuildIcon from '@mui/icons-material/Build';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PetsIcon from '@mui/icons-material/Pets';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function Interests1() {
    const maxCharacters = 50;
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [customInterest, setCustomInterest] = useState('');
    const [charCount, setCharCount] = useState(maxCharacters);
    const [hoveredTile, setHoveredTile] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const auth = getAuth();
    const [userName, setUserName] = useState('');
    const [virtualFriendName, setVirtualFriendName] = useState('');
    const [fromSummary, setFromSummary] = useState(false);
    useScrollToTop();

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                }

                if (location.state?.virtualFriendId) {
                    const isFromSummary = location.state.fromSummary || false;
                    setFromSummary(isFromSummary);

                    const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', location.state.virtualFriendId);
                    const virtualFriendSnap = await getDoc(virtualFriendRef);
                    if (virtualFriendSnap.exists()) {
                        setVirtualFriendName(virtualFriendSnap.data().name);
                        const data = virtualFriendSnap.data();
                        const existingInterests = data.interests || [];
                        const existingCustomInterest = existingInterests.find(interest => !interestsOptions.some(option => option.description === interest));

                        setSelectedInterests(existingInterests.filter(interest => interest !== existingCustomInterest));
                        if (existingCustomInterest) {
                            setCustomInterest(existingCustomInterest);
                        }
                    }
                } else {
                    alert("Invalid access. Redirecting to start.");
                    navigate("/login");
                }
            } else {
                navigate('/login');
            }
        });
    }, [auth, db, navigate, location.state]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleSelection = (interest) => {
        if (selectedInterests.includes(interest)) {
            setSelectedInterests(selectedInterests.filter(i => i !== interest));
        } else {
            if (selectedInterests.length < 3) {
                setSelectedInterests([...selectedInterests, interest]);
            } else {
                alert('You can only select up to 3 interests.');
            }
        }
    };

    const interestsOptions = [
        { description: "Arts and culture.", icon: <MusicNoteIcon style={{ fontSize: 80 }} /> },
        { description: "Technology and innovation.", icon: <ComputerIcon style={{ fontSize: 80 }} /> },
        { description: "Sports and fitness.", icon: <SportsSoccerIcon style={{ fontSize: 80 }} /> },
        { description: "Literature and writing.", icon: <BookIcon style={{ fontSize: 80 }} /> },
        { description: "Gaming and entertainment.", icon: <SportsEsportsIcon style={{ fontSize: 80 }} /> },
        { description: "Cooking and culinary arts.", icon: <RestaurantIcon style={{ fontSize: 80 }} /> },
        { description: "Nature and the outdoors.", icon: <NaturePeopleIcon style={{ fontSize: 80 }} /> },
        { description: "Science and education.", icon: <SchoolIcon style={{ fontSize: 80 }} /> },
        { description: "Politics and social issues.", icon: <GavelIcon style={{ fontSize: 80 }} /> },
        { description: "Travel and exploration.", icon: <FlightTakeoffIcon style={{ fontSize: 80 }} /> },
        { description: "Fashion and lifestyle.", icon: <CheckroomIcon style={{ fontSize: 80 }} /> },
        { description: "DIY and crafting.", icon: <BuildIcon style={{ fontSize: 80 }} /> },
        { description: "Music and instruments.", icon: <QueueMusicIcon style={{ fontSize: 80 }} /> },
        { description: "Photography and videography.", icon: <CameraAltIcon style={{ fontSize: 80 }} /> },
        { description: "Health and wellness.", icon: <FavoriteIcon style={{ fontSize: 80 }} /> },
        { description: "Astronomy and space exploration.", icon: <SpaceBarIcon style={{ fontSize: 80 }} /> },
        { description: "Philosophy and psychology.", icon: <PsychologyIcon style={{ fontSize: 80 }} /> },
        { description: "Pets and animal care.", icon: <PetsIcon style={{ fontSize: 80 }} /> },
        { description: "Investing and financial planning.", icon: <TrendingUpIcon style={{ fontSize: 80 }} /> },
    ];

    const handleCustomInterestChange = (e) => {
        const input = e.target.value;
        setCustomInterest(input);
        setCharCount(maxCharacters - input.length);
    };

    const handleSaveChanges = async () => {
        if (!selectedInterests.length && !customInterest) {
            alert('Please select at least one interest or add a custom interest.');
            return;
        }

        const interestsToSave = customInterest ? [...selectedInterests, customInterest] : selectedInterests;
        if (interestsToSave.length > 3) {
            alert('Please select up to 3 interests including your custom interest.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await updateDoc(virtualFriendRef, { interests: interestsToSave });
            navigate('/summary', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating interests: ", error);
        }
    };

    const handleSubmit = async () => {
        const interestsToSave = customInterest ? [...selectedInterests, customInterest] : selectedInterests;
        if (interestsToSave.length < 1) {
            alert('Please select at least one interest.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { interests: interestsToSave }, { merge: true });
            navigate('/commstyle', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating interests: ", error);
        }
    };

    const goBack = () => {
        if (fromSummary) {
            navigate('/summary', { state: { virtualFriendId: location.state.virtualFriendId } });
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container maxWidth="xl" style={{ marginTop: '100px', padding: '10px', textAlign: 'center', marginBottom: '40px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '60px', fontWeight: 'bold', fontSize: '2.8rem'}}>
                    What hobbies or interests does {virtualFriendName} have? <span style={{ fontSize: '2.2rem' }}><i>(choose up to three)</i></span>
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {interestsOptions.map((interest, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Paper 
                                style={{ 
                                    padding: '40px', 
                                    backgroundColor: selectedInterests.includes(interest.description) ? '#ff7f50ff' : 'black', 
                                    color: 'white', 
                                    cursor: 'pointer', 
                                    textAlign: 'center', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    height: '150px',
                                    width: '320px',
                                    transition: 'transform 0.3s ease',
                                    transform: hoveredTile === interest.description || selectedInterests.includes(interest.description) ? 'scale(1.05)' : 'scale(1)',
                                }} 
                                elevation={3}
                                onClick={() => handleSelection(interest.description)}
                                onMouseOver={() => setHoveredTile(interest.description)}
                                onMouseOut={() => setHoveredTile(null)}
                            >
                                {interest.icon}
                                <Typography variant="subtitle1" style={{ color: 'white', marginTop: '15px' }}>
                                    {interest.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <TextField
                    label="Add custom interests"
                    placeholder="Separate multiple interests by commas (e.g., Gardening, World of Warcraft, Football)"
                    type="text"
                    value={customInterest}
                    onChange={handleCustomInterestChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: maxCharacters }}
                    sx={{ mt: 10 }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                    {charCount} characters remaining
                </Typography>
                <Container maxWidth="md" style={{ marginTop: '10px', marginBottom: '40px', textAlign: 'center' }}>
                    <Typography variant="h5" style={{ marginBottom: '30px', color: 'black' }}>
                        <b>Tips for Choosing Custom Interests:</b>
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff', height: '200px' }}>
                                <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Good Examples</b></Typography>
                                <Typography variant="body2" style={{ color: 'black' }}>
                                    Anime, Video Games, Astrology
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff', height: '200px' }}>
                                <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>How Pali Reads It</b></Typography>
                                <Typography variant="body2" style={{ color: 'black' }}>
                                    Interests: (custom interests)
                                </Typography>
                                <Typography variant="body2" style={{ color: 'black', marginTop: '10px' }}>
                                    <b>Choose custom interests that fit into that sentence neatly to create the best Pali possible!</b>
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff', height: '200px' }}>
                                <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Avoid</b></Typography>
                                <Typography variant="body2" style={{ color: 'black' }}>
                                    Too many specific details or interests that are too niche.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Button 
                    variant="contained" 
                    onClick={fromSummary ? handleSaveChanges : handleSubmit}
                    sx={{
                        margin: '20px 0',
                        height: '50px', 
                        width: '100%',
                        backgroundColor: 'black', 
                        color: 'white', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    {fromSummary ? 'Save Changes' : 'Next Step'}
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={goBack}
                    sx={{
                        margin: '20px 0',
                        height: '50px', 
                        width: '100%',
                        color: 'black', 
                        '&:hover': { 
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Interests1;
