import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, TextField } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

import PeopleIcon from '@mui/icons-material/People';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MoodIcon from '@mui/icons-material/Mood';
import CakeIcon from '@mui/icons-material/Cake';
import SchoolIcon from '@mui/icons-material/School';
import BookIcon from '@mui/icons-material/Book';
import GavelIcon from '@mui/icons-material/Gavel';
import LockIcon from '@mui/icons-material/Lock';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';

function RelationshipStatus() {
    const maxCharacters = 50;
    const [relationship, setRelationship] = useState('');
    const [customRelationship, setCustomRelationship] = useState('');
    const [charCount, setCharCount] = useState(maxCharacters);
    const [selectedTile, setSelectedTile] = useState('');
    const [hoveredTile, setHoveredTile] = useState(null);
    const [virtualFriendName, setVirtualFriendName] = useState('');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const auth = getAuth();
    const fromSummary = location.state?.fromSummary;
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                }

                if (location.state?.virtualFriendId) {
                    const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', location.state.virtualFriendId);
                    const virtualFriendSnap = await getDoc(virtualFriendRef);
                    if (virtualFriendSnap.exists()) {
                        setVirtualFriendName(virtualFriendSnap.data().name);
                        if (location.state.fromSummary) {
                            const currentRelationship = virtualFriendSnap.data().relationship;
                            setRelationship(currentRelationship);
                            const foundOption = relationships.find(option => option.description === currentRelationship);
                            if (foundOption) {
                                setSelectedTile(foundOption.label);
                            } else {
                                setCustomRelationship(currentRelationship);
                            }
                        }
                    } else {
                        alert("Invalid access. Redirecting to start.");
                        navigate("/login");
                    }
                } else {
                    alert("Invalid access. Redirecting to login.");
                    navigate("/login");
                }
            } else {
                navigate("/login");
            }
        });

        return () => unsubscribe();
    }, [auth, db, navigate, location.state?.virtualFriendId, location.state?.fromSummary]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const relationships = [
        { label: 'Girlfriend', description: 'Girlfriend: A romantic partner who provides companionship and a dating dynamic.', icon: <FavoriteIcon style={{ fontSize: 80 }} /> },
        { label: 'Boyfriend', description: 'Boyfriend: A romantic partner who offers companionship and a dating experience.', icon: <FitnessCenterIcon style={{ fontSize: 80 }} /> },
        { label: 'Wife', description: 'Wife: A virtual spouse offering a committed, matrimonial-like relationship experience.', icon: <MoodIcon style={{ fontSize: 80 }} /> },
        { label: 'Husband', description: 'Husband: A virtual spouse for a committed, matrimonial-like partnership.', icon: <MoodIcon style={{ fontSize: 80 }} /> },
        { label: 'Partner', description: 'Partner: A committed companion, reflecting a modern, non-gender-specific relationship.', icon: <CakeIcon style={{ fontSize: 80 }} /> },
        { label: 'Friend', description: 'Friend: A companion for casual social interaction and companionship.', icon: <PeopleIcon style={{ fontSize: 80 }} /> },
        { label: 'Mentor', description: 'Mentor: Someone who provides wisdom, guidance, and knowledge.', icon: <SchoolIcon style={{ fontSize: 80 }} /> },
        { label: 'Study Buddy', description: 'Study Buddy: Ideal for those looking to share academic or educational goals.', icon: <BookIcon style={{ fontSize: 80 }} /> },
        { label: 'Debate Partner', description: 'Debate Partner: A clever debate partner to debate any issue.', icon: <GavelIcon style={{ fontSize: 80 }} /> },
        { label: 'Coach', description: 'Coach: Offers encouragement and support in personal growth and achievements.', icon: <FitnessCenterIcon style={{ fontSize: 80 }} /> },
        { label: 'Confidant', description: 'Confidant: A trusted individual for private conversations and advice.', icon: <LockIcon style={{ fontSize: 80 }} /> },
        { label: 'Motivator', description: 'Motivator: Inspires and encourages the user towards their goals and dreams.', icon: <SportsHandballIcon style={{ fontSize: 80 }} /> },
    ];

    const handleTileClick = (option) => {
        setRelationship(option.label);
        setSelectedTile(option.label);
    };

    const handleTileHover = (value) => {
        if (!fromSummary) {
            setHoveredTile(value);
        }
    };

    const handleCustomRelationshipChange = (e) => {
        const input = e.target.value;
        setCustomRelationship(input);
        setCharCount(maxCharacters - input.length);
    };

    const handleSaveChanges = async () => {
        if (!relationship && !customRelationship) {
            alert('Please select a type of relationship or enter a custom type.');
            return;
        }

        let relationshipToSave;

        if (customRelationship) {
            relationshipToSave = customRelationship;
        } else if (relationship) {
            relationshipToSave = relationship;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await updateDoc(virtualFriendRef, { relationship: relationshipToSave });
            navigate('/summary', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating relationship status: ", error);
        }
    };

    const handleSubmit = async () => {
        let relationshipToSave = customRelationship;
        if (!relationshipToSave && relationship) {
            relationshipToSave = relationship;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { relationship: relationshipToSave }, { merge: true });
            navigate('/personality1', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating relationship type: ", error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container maxWidth="xl" style={{ marginTop: '100px', padding: '10px', textAlign: 'center', marginBottom: '40px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '30px', fontWeight: 'bold', fontSize: '2.8rem', marginBottom: '50px' }}>
                    {userName}, what kind of relationship are you looking to build with {virtualFriendName}? <span style={{ fontSize: '2.2rem' }}><i>(choose one)</i></span>
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {relationships.map((option, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Paper
                                style={{
                                    padding: '40px',
                                    backgroundColor: selectedTile === option.label ? '#ff7f50ff' : 'black',
                                    color: 'white',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '150px',
                                    width: '320px',
                                    transition: 'transform 0.3s ease',
                                    transform: hoveredTile === option.label || selectedTile === option.label ? 'scale(1.05)' : 'scale(1)',
                                }}
                                elevation={3}
                                onClick={() => handleTileClick(option)}
                                onMouseOver={() => handleTileHover(option.label)}
                                onMouseOut={() => setHoveredTile(null)}
                            >
                                {hoveredTile === option.label || selectedTile === option.label ? (
                                    <Typography variant="subtitle1" style={{ color: 'white' }}>
                                        {option.description}
                                    </Typography>
                                ) : (
                                    <>
                                        {option.icon}
                                        <Typography variant="subtitle1" style={{ color: 'white', marginTop: '15px' }}>
                                            {option.label}
                                        </Typography>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <TextField
                    label="Or enter a custom relationship"
                    type="text"
                    value={customRelationship}
                    onChange={handleCustomRelationshipChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: maxCharacters }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                    {charCount} characters remaining
                </Typography>
                <Container maxWidth="md" style={{ marginTop: '10px', marginBottom: '40px', textAlign: 'center' }}>
                    <Typography variant="h5" style={{ marginBottom: '30px', color: 'black' }}>
                        <b>Tips for Creating Custom Relationships:</b>
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff', height: '200px' }}>
                                <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Good Examples</b></Typography>
                                <Typography variant="body2" style={{ color: 'black' }}>
                                    Lover, Gym Buddy, Grandma, Math Tutor
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff', height: '200px' }}>
                                <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>How Pali Reads It</b></Typography>
                                <Typography variant="body2" style={{ color: 'black' }}>
                                    You are {userName}'s (custom relationship)
                                </Typography>
                                <Typography variant="body2" style={{ color: 'black', marginTop: '10px' }}>
                                    <b>Choose a custom input that fits in that sentence neatly to create the best Pali possible!</b>
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff', height: '200px' }}>
                                <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Avoid</b></Typography>
                                <Typography variant="body2" style={{ color: 'black' }}>
                                    Specifying personality traits or detailed backstories here, as those will be developed in subsequent steps.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Button
                    variant="contained"
                    onClick={fromSummary ? handleSaveChanges : handleSubmit}
                    sx={{
                        margin: '20px 0',
                        marginTop: '30px',
                        height: '50px',
                        width: '100%',
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '1rem',
                        '&:hover': {
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    {fromSummary ? 'Save Changes' : 'Next Step'}
                </Button>
                <Button
                    variant="outlined"
                    onClick={goBack}
                    sx={{
                        margin: '20px 0',
                        height: '50px',
                        width: '100%',
                        color: 'black',
                        borderColor: 'black',
                        fontSize: '1rem',
                        '&:hover': {
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default RelationshipStatus;
