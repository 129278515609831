import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, Divider, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import useScrollToTop from './useScrollToTop';

const PremiumSignup2 = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const functions = getFunctions();
  const stripePromise = loadStripe('pk_live_51Mw39FGvdtye7cZUJcRfirgbzZZWdOnBqZv1GaoxwVWQb3ULkctFGG1qW0BFtdDLREtGoYs36x87NQxXGEyAbMfe000sbk1ntU');
  useScrollToTop();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    signOut(auth).then(() => navigate('/login')).catch((error) => console.error('Logout Error:', error));
  };

  const [loading, setLoading] = useState({});

  const handleStripeRedirect = async (priceId) => {
    setLoading(prevLoading => ({ ...prevLoading, [priceId]: true }));

    try {
      const stripe = await stripePromise;
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionWithoutFriendId');
      const result = await createCheckoutSession({ priceId });
      await stripe.redirectToCheckout({ sessionId: result.data.sessionId });
    } catch (error) {
      console.error("Error creating checkout session:", error.message);
      alert("Error processing your request: " + error.message);
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, [priceId]: false }));
    }
  };

  const tiers = [
    { name: 'Basic', priceId: 'price_1OyMIEGvdtye7cZUC1UoV9OZ', price: '$8/month', texts: '150 texts/month' },
    { name: 'Plus', priceId: 'price_1OyMIMGvdtye7cZUfWgtOt3s', price: '$15/month', texts: '450 texts/month' },
    { name: 'Premium', priceId: 'price_1OyMISGvdtye7cZUSXYUmEvN', price: '$30/month', texts: '900 texts/month' },
    { name: 'Pro', priceId: 'price_1OyMIVGvdtye7cZUL84bE4L2', price: '$45/month', texts: '1500 texts/month' },
  ];

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Toolbar>
          <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
          </Link>
          <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
          <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
          <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
          <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
          <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
        </Toolbar>
      </AppBar>

      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', marginTop: '1px' }}>
        <Container maxWidth={false} style={{ flexGrow: 1, padding: isMobile ? '100px 20px' : '160px 20px', backgroundColor: '#eeebea', textAlign: 'center' }}>
          <Typography variant={isMobile ? 'h4' : 'h2'} style={{ color: '#ff7f50ff', marginBottom: '30px' }}><b>Start chatting with your Pali now!</b></Typography>
          <Typography variant={isMobile ? 'h5' : 'h3'} style={{ color: '#ff7f50ff', marginBottom: '50px' }}>Unlock the full experience and <b>never miss a moment.</b></Typography>
          <Grid container spacing={isMobile ? 3 : 6} justifyContent="center" alignItems="center">
            {tiers.map((tier, index) => (
              <Grid item key={index} xs={12} sm={6} md={3}>
                <Paper elevation={3} style={{ padding: isMobile ? '40px' : '20px', backgroundColor: 'white', textAlign: 'center', height: '100%' }}>
                  <Typography variant={isMobile ? 'h6' : 'h5'} style={{ marginBottom: '15px', fontWeight: 'bold' }}>{tier.name}</Typography>
                  <Typography variant="body1" style={{ marginBottom: '15px' }}>{tier.price}</Typography>
                  <Typography variant="body2" style={{ marginBottom: '20px' }}>{tier.texts}</Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleStripeRedirect(tier.priceId)}
                    disabled={loading[tier.priceId]} // Use specific loading state for this button
                    sx={{
                      backgroundColor: '#ff7f50ff',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: isMobile ? '8px 16px' : '10px 25px',
                      fontSize: isMobile ? '0.875rem' : '1rem',
                      marginTop: 'auto', // Ensure the button stays at the bottom
                    }}
                  >
                    {loading[tier.priceId] ? <CircularProgress size={24} color="inherit" /> : `Choose ${tier.name}`}
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Divider style={{ backgroundColor: 'black', height: '2px' }} />

        <Container maxWidth={false} style={{ backgroundColor: '#6495edff', padding: '50px 20px', textAlign: 'center' }}>
          <Typography variant={isMobile ? 'h5' : 'h3'} style={{ color: 'black', marginBottom: '30px', fontWeight: 'bold' }}>Not ready to upgrade? All users get 10 free messages to get started.</Typography>
          <Typography variant={isMobile ? 'h6' : 'h5'} style={{ color: 'black', marginBottom: '20px', fontWeight: 'bold' }}>Start Chatting Today!</Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/customize")}
            sx={{
              backgroundColor: '#ff7f50ff',
              color: 'white',
              fontWeight: 'bold',
              padding: isMobile ? '8px 16px' : '10px 25px',
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
          >
            Try It Out
          </Button>
        </Container>

        <Divider style={{ backgroundColor: 'black', height: '2px', margin: '0 auto', width: '100%' }} />

        <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
          <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>© 2024 Dreamforge LLC</Typography>
          <Grid container justifyContent="center">
            <Grid item xs={6} sm={3}>
              <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
            </Grid>
            <Grid item xs={6} sm={3}>
              <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default PremiumSignup2;
