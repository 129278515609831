import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, TextField } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ComputerIcon from '@mui/icons-material/Computer';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import BookIcon from '@mui/icons-material/Book';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import SchoolIcon from '@mui/icons-material/School';
import GavelIcon from '@mui/icons-material/Gavel';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import BuildIcon from '@mui/icons-material/Build';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SpaceBarIcon from '@mui/icons-material/SpaceBar'; // Note: This might not be the exact icon for astronomy/space. Adjust as necessary.
import PsychologyIcon from '@mui/icons-material/Psychology';
import PetsIcon from '@mui/icons-material/Pets';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function PaliInterests1() {
    const maxCharacters = 50;
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [customInterest, setCustomInterest] = useState('');
    const [charCount, setCharCount] = useState(maxCharacters);
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const [userName, setUserName] = useState('');
    const auth = getAuth();
    useScrollToTop();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                getDoc(doc(db, "users", user.uid)).then(docSnap => {
                    if (docSnap.exists()) {
                        setUserName(docSnap.data().name);
                    }
                });
            } else {
                navigate("/login");
            }
        });
    }, [auth, db, navigate]);

    if (!location.state || !location.state.virtualFriendId) {
        alert("Invalid access. Redirecting to start.");
        navigate("/login");
        return null;
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleSelection = (interest) => {
        if (selectedInterests.includes(interest)) {
            setSelectedInterests(selectedInterests.filter(i => i !== interest));
        } else {
            if (selectedInterests.length < 3) {
                setSelectedInterests([...selectedInterests, interest]);
            } else {
                alert('You can only select up to 3 interests.');
            }
        }
    };

    const interestsOptions = [
        { description: "Arts and culture.", icon: <MusicNoteIcon style={{ fontSize: 80 }} /> },
        { description: "Technology and innovation.", icon: <ComputerIcon style={{ fontSize: 80 }} /> },
        { description: "Sports and fitness.", icon: <SportsSoccerIcon style={{ fontSize: 80 }} /> },
        { description: "Literature and writing.", icon: <BookIcon style={{ fontSize: 80 }} /> },
        { description: "Gaming and entertainment.", icon: <SportsEsportsIcon style={{ fontSize: 80 }} /> },
        { description: "Cooking and culinary arts.", icon: <RestaurantIcon style={{ fontSize: 80 }} /> },
        { description: "Nature and the outdoors.", icon: <NaturePeopleIcon style={{ fontSize: 80 }} /> },
        { description: "Science and education.", icon: <SchoolIcon style={{ fontSize: 80 }} /> },
        { description: "Politics and social issues.", icon: <GavelIcon style={{ fontSize: 80 }} /> },
        { description: "Travel and exploration.", icon: <FlightTakeoffIcon style={{ fontSize: 80 }} /> },
        { description: "Fashion and lifestyle.", icon: <CheckroomIcon style={{ fontSize: 80 }} /> },
        { description: "DIY and crafting.", icon: <BuildIcon style={{ fontSize: 80 }} /> },
        { description: "Music and instruments.", icon: <QueueMusicIcon style={{ fontSize: 80 }} /> },
        { description: "Photography and videography.", icon: <CameraAltIcon style={{ fontSize: 80 }} /> },
        { description: "Health and wellness.", icon: <FavoriteIcon style={{ fontSize: 80 }} /> },
        { description: "Astronomy and space exploration.", icon: <SpaceBarIcon style={{ fontSize: 80 }} /> },
        { description: "Philosophy and psychology.", icon: <PsychologyIcon style={{ fontSize: 80 }} /> },
        { description: "Pets and animal care.", icon: <PetsIcon style={{ fontSize: 80 }} /> },
        { description: "Investing and financial planning.", icon: <TrendingUpIcon style={{ fontSize: 80 }} /> },
        // Add more interests as needed
    ];


    const handleCustomInterestChange = (e) => {
        const input = e.target.value;
        setCustomInterest(input);
        setCharCount(maxCharacters - input.length);
    };

    const handleSubmit = async () => {
        const interestsToSave = customInterest ? [...selectedInterests, customInterest] : selectedInterests;
        if (interestsToSave.length > 3) {
            alert('Please select up to 3 interests including your custom interest.');
            return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);

        try {
            await setDoc(virtualFriendRef, { interests: interestsToSave }, { merge: true });
            navigate('/editpali', { state: { virtualFriendId } }); // Change this line
        } catch (error) {
            console.error("Error updating interests: ", error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>

            <Container maxWidth="xl" style={{ marginTop: '100px', padding: '10px', textAlign: 'center', marginBottom: '200px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '60px', fontWeight: 'bold', fontSize: '2.8rem' }}>
                {userName}, what hobbies or interests should your Pali have? <span style={{ fontSize: '2.2rem' }}><i>(choose up to three)</i></span>
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {interestsOptions.map((interest, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Paper 
                                style={{ 
                                    padding: '40px', 
                                    backgroundColor: selectedInterests.includes(interest.description) ? '#ff7f50ff' : 'black', 
                                    color: 'white',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '150px',
                                    width: '320px',
                                }} 
                                elevation={3}
                                onClick={() => handleSelection(interest.description)}
                            >
                                {interest.icon}
                                <Typography variant="subtitle1" style={{ color: 'white', marginTop: '15px' }}>
                                    {interest.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <TextField
                    label="Add a custom interest"
                    type="text"
                    value={customInterest}
                    onChange={handleCustomInterestChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: maxCharacters }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                    {charCount} characters remaining
                </Typography>
                <div style={{ textAlign: 'left', margin: '20px 0', color: 'black', fontSize: '18px' }}>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Tips for Choosing Custom Interests:
                    </Typography>
                    <ul>
                    <li>Your Pali will read this input as: "Interests: (custom interests)"<b> so choose custom interests that fit into that sentence neatly to create the best Pali possible!</b></li>
                    <li>If you enter multiple custom interests, please seperate them by a comma. (example: Gardening, World of Warcraft, Football)</li>
                    <li>Examples of good custom interests are: "Anime, Video Games, Astrology". You can also be very specific such as "Lord of the Rings, The Cleveland Browns, etc"</li>
                        <li>Your Pali will be able to talk about any popular interest that exists on the internet up to 2023. Things that happened very recently may not work!</li>
                    </ul>
                </div>
                <Button 
                    variant="contained" 
                    onClick={handleSubmit}
                    sx={{
                        margin: '20px 0',
                        marginTop: '50px',
                        height: '50px', 
                        width: '100%',
                        backgroundColor: 'black', 
                        color:
                        'white',
                        '&:hover': {
                        backgroundColor: '#ff7f50ff'
                        }
                        }}
                        >
                        Save Changes
                        </Button>
                        <Button
                        variant="outlined"
                        onClick={goBack}
                        sx={{
                        margin: '20px 0',
                        height: '50px',
                        width: '100%',
                        color: 'black',
                        '&:hover': {
                        backgroundColor: '#ff7f50ff'
                        }
                        }}
                        >
                        Go Back
                        </Button>
                        </Container>
                        <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
      <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
                            </>
                        );
                        }
                        
                        export default PaliInterests1;
