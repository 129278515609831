import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, CircularProgress } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc, deleteDoc, getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import useScrollToTop from './useScrollToTop';

// Initialize functions
const functions = getFunctions();
const generateAndSendText = httpsCallable(functions, 'generateAndSendText');
const sendVirtualFriendImage = httpsCallable(functions, 'sendVirtualFriendImage'); // Add this line

function Friend() {
  const [friendData, setFriendData] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false); // Add this line for new button's loading state
  const { friendId } = useParams(); // useParams used correctly
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  useScrollToTop();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Use friendId directly from useParams
        const friendRef = doc(db, 'users', user.uid, 'virtualFriends', friendId);
        const docSnap = await getDoc(friendRef);
        if (docSnap.exists()) {
          setFriendData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, db, friendId, navigate]); // Include friendId in dependency array

  const setActiveFriend = async () => {
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, { activeVirtualFriendId: friendId });
    navigate('/managefriends');
  };

  const sendTextMessage = async () => {
    if (!friendId) {
      console.log("Virtual friend ID is missing.");
      return;
    }
    setLoading(true);
    try {
      const result = await generateAndSendText({ virtualFriendId: friendId });
      // Check the response for a limit message or code
      if (result.data.code === 429) {
        // This assumes the function returns a code or a specific message to indicate the limit is reached
        alert(result.data.generatedText); // Display the system message about reaching the limit
      } else {
        // Handle success scenario
        console.log("Text message sent successfully.");
      }
    } catch (error) {
      console.error("An error occurred: ", error);
    }
    setLoading(false);
  };
  

  const deleteFriend = async () => {
    if (window.confirm('Are you sure you want to delete this friend?')) {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userData = await getDoc(userRef);
      if (userData.exists() && userData.data().activeVirtualFriendId === friendId) {
        await updateDoc(userRef, {
          activeVirtualFriendId: null
        });
      }
      const friendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', friendId);
      await deleteDoc(friendRef);
      navigate('/managefriends');
    }
  };

  const sendImageLink = async () => {
    if (!friendId) {
      console.log("Virtual friend ID is missing.");
      return;
    }
    setImageLoading(true);
    try {
      await sendVirtualFriendImage({ virtualFriendId: friendId });
      console.log("Image link sent successfully");
    } catch (error) {
      console.error("Error sending image link: ", error);
    }
    setImageLoading(false);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>

      <Container component="main" maxWidth="md" style={{ flex: 1, marginTop: '100px', padding: '20px', textAlign: 'center', marginBottom: '80px' }}>
        <Typography variant="h4" gutterBottom style={{ marginBottom: '30px' }}>
          Friend Summary
        </Typography>
        {friendData.imageUrl && (
          <img src={friendData.imageUrl} alt="Virtual Friend" style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px', border: '4px solid black' }} />
        )}
<Paper style={{ padding: '20px', marginBottom: '20px' }}>
          <Typography variant="subtitle1"><strong>Name:</strong> {friendData.name}</Typography>
          <Typography variant="subtitle1"><strong>Relationship:</strong> {friendData.relationship}</Typography>
          <Typography variant="subtitle1"><strong>Personality Traits:</strong> {friendData.traits?.join(', ')}</Typography>
          <Typography variant="subtitle1"><strong>Personality Keywords:</strong> {friendData.personalityKeywords?.join(', ')}</Typography>
          <Typography variant="subtitle1"><strong>General Interests:</strong> {friendData.interests?.join(', ')}</Typography>
          <Typography variant="subtitle1"><strong>Specific Interests:</strong> {friendData.specificInterests?.join(', ')}</Typography>
          <Typography variant="subtitle1"><strong>Communication Style:</strong> {friendData.communicationStyle}</Typography>
          <Typography variant="subtitle1"><strong>Backstory:</strong> {friendData.backstory}</Typography>
          <Typography variant="subtitle1"><strong>Opt-in for Messages:</strong> {friendData.virtualTextMessaging === 'yes' ? 'Yes' : 'No'}</Typography>
          <Typography variant="subtitle1"><strong>Preferred Message Time:</strong> {friendData.preferredMessageTime}</Typography>
          <Typography variant="subtitle1"><strong>Status:</strong> {friendData.isFinalized ? 'Finalized' : 'In Progress'}</Typography>
        </Paper>
        <Button onClick={setActiveFriend} variant="contained" color="success" sx={{ margin: '10px', width: '50%', fontSize: '1.2rem' }}>
          Set as Active Friend
        </Button>
        <Button onClick={sendTextMessage} variant="contained" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, margin: '10px', width: '50%', fontSize: '1.2rem' }} disabled={loading}>
          {loading ? <CircularProgress color="inherit" /> : 'Send me a text!'}
        </Button>
        <Button 
          onClick={sendImageLink} 
          variant="contained" 
          sx={{ 
            backgroundColor: 'black', 
            color: 'white', 
            '&:hover': { backgroundColor: '#ff7f50ff' }, 
            margin: '10px', 
            width: '50%', 
            fontSize: '1.2rem' 
          }} 
          disabled={imageLoading}
        >
          {imageLoading ? <CircularProgress color="inherit" /> : 'Send Image Link'}
        </Button>
        <Button
  onClick={() => navigate('/editpali', { state: { virtualFriendId: friendId } })}
  variant="contained"
  sx={{
    backgroundColor: 'black',
    color: 'white',
    '&:hover': { backgroundColor: '#ff7f50ff' },
    margin: '10px',
    width: '50%',
    fontSize: '1.2rem'
  }}
>
  Edit Information
</Button>
        <Button onClick={deleteFriend} variant="contained" color="error" sx={{ margin: '10px', width: '50%', fontSize: '1.2rem' }}>
          Delete Friend
        </Button>
        <Button onClick={() => navigate('/managefriends')} variant="outlined" sx={{ backgroundColor: 'black', margin: '10px', borderColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, width: '50%', fontSize: '1.2rem' }}>
          Back to Manage Friends
        </Button>
      </Container>

      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Friend;
