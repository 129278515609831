import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper } from '@mui/material';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PublicIcon from '@mui/icons-material/Public';
import useScrollToTop from './useScrollToTop';


const AboutUsPage = () => {
  useScrollToTop();
  return (
    <>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
  <Toolbar>
    <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ marginRight: '20px', height: '35px' }} />
    <Link to="/" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
    <Link to="/aboutpage" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>About</Link>
    <Link to="/pricing" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Pricing</Link>
    <Button variant="outlined" color="inherit" component={Link} to="/login" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Login</Button>
    <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Sign Up</Button>
  </Toolbar>
</AppBar>

      <Container maxWidth={false} style={{ backgroundColor: '#6495edff', padding: '70px 20px', textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h3" gutterBottom style={{ color: 'black', marginBottom: '30px', fontSize: '3.7rem' }}>
          <b>About Dreamforge</b>
        </Typography>
        <Typography variant="h5" gutterBottom style={{ color: 'black', marginBottom: '20px', fontSize: '1.8rem' }}>
          Leveraging AI for Better Communication while improving the lives of people
        </Typography>
      </Container>

      <Container maxWidth="lg" style={{ backgroundColor: '#ffffff', padding: '60px 20px', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif', marginBottom: '970px' }}>
        <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '30px', color: 'black', textAlign: 'center' }}>
                At Dreamforge, our mission is to leverage the AI revolution to better humanity, offering up better ways to work together, communicate more effectively, and <b><i>empower every aspect of our lives.</i></b>
            </Typography>
        </Grid>
          <Grid item xs={12} md={4}>
            <Paper style={{ padding: '20px', backgroundColor: 'white' }} elevation={3}>
              <SmartphoneIcon style={{ fontSize: 60, color: 'black' }} />
              <Typography variant="h6" style={{ marginTop: '10px', color: 'black' }}>
                Always Connected
              </Typography>
              <Typography variant="body2" style={{ color: 'black' }}>
                With Pali, you're never alone. Access your digital companion anytime, anywhere without the need for a separate app. Texting feels more real than app based communications, and we like that.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper style={{ padding: '20px', backgroundColor: 'white' }} elevation={3}>
              <PeopleAltIcon style={{ fontSize: 60, color: 'black' }} />
              <Typography variant="h6" style={{ marginTop: '10px', color: 'black' }}>
                Breaking Loneliness
              </Typography>
              <Typography variant="body2" style={{ color: 'black' }}>
                Our goal is to provide a companion for those who need someone to talk to, anytime, fostering connections and breaking down loneliness barriers.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper style={{ padding: '20px', backgroundColor: 'white' }} elevation={3}>
              <PublicIcon style={{ fontSize: 60, color: 'black' }} />
              <Typography variant="h6" style={{ marginTop: '10px', color: 'black' }}>
                AI for Humanity
              </Typography>
              <Typography variant="body2" style={{ color: 'black' }}>
                Dreamforge is at the forefront of utilizing AI advancements to enhance human communication and interactions, making a positive impact on society.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '40px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
            <Typography variant="body1" style={{ color: 'white', marginBottom: '30px', fontWeight: 'bold' }}>
                © 2024 Dreamforge LLC
            </Typography>
            <Grid container justifyContent="center">
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Link to="/aboutdream" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
    </Grid>
    <Grid item xs={6} sm={3}>
        <Link to="/howitworks" style={{ color: 'white', textDecoration: 'none' }}>How It Works</Link>
    </Grid>
</Grid>

        </Container>
    </>
  );
};

export default AboutUsPage;
