import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, getFirestore } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

function Summary() {
  const [virtualFriendData, setVirtualFriendData] = useState({});
  const [userName, setUserName] = useState('');
  const [optInForMessages, setOptInForMessages] = useState(false);
  const [messageTime, setMessageTime] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore();
  const auth = getAuth();
  useScrollToTop();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserName(userSnap.data().name);
        }

        if (!location.state || !location.state.virtualFriendId) {
          alert("Invalid access. Redirecting to start.");
          navigate("/start");
          return;
        }

        const virtualFriendId = location.state.virtualFriendId;
        const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', virtualFriendId);
        const docSnap = await getDoc(virtualFriendRef);
        if (docSnap.exists()) {
          setVirtualFriendData(docSnap.data());
          setOptInForMessages(docSnap.data().virtualTextMessaging === 'yes');
          setMessageTime(docSnap.data().preferredMessageTime || '');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, db, navigate, location]);

  const finalizeSettings = async () => {
    const userId = auth.currentUser.uid;
    const virtualFriendId = location.state.virtualFriendId;
  
    if (!userId || !virtualFriendId) {
      console.error("UserID or VirtualFriendID is undefined");
      return;
    }
  
    const virtualFriendRef = doc(db, 'users', userId, 'virtualFriends', virtualFriendId);
    const userRef = doc(db, 'users', userId);
  
    try {
      await setDoc(virtualFriendRef, {
        isFinalized: true,
        optInForMessages
      }, { merge: true });
  
      await updateDoc(userRef, {
        activeVirtualFriendId: virtualFriendId
      });
  
      alert('Settings finalized and active virtual friend updated!');
      navigate('/premsignup', { state: { virtualFriendId } });
    } catch (error) {
      console.error("Error finalizing settings: ", error);
    }
  };

  const navigateToEdit = (key) => {
    const pageMap = {
        "imagedescription": "/imagegen",
        "companiontype": "/companiontype",
        "relationshipstatus": "/relationshipstatus",
        "personalitytraits": "/personality1",
        "generalinterests": "/interests1",
        "specificinterests": "/interests2",
        "communicationstyle": "/commstyle",
        "backstory": "/backstory",
        "friendtextsfirst": "/getmessage",
        "friendname": "/customize"
    };

    const route = pageMap[key.toLowerCase().replace(/\s+/g, '')];
    if (route) {
        navigate(route, { state: { virtualFriendId: location.state.virtualFriendId, fromSummary: true } });
    } else {
        console.error('No edit route defined for this key');
    }
};

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Toolbar>
            <Link to="/account" style={{ marginRight: '10px' }}>
                <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
            </Link>
            <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
            <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
            <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
            <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
            <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth="md" style={{ marginTop: '100px', padding: '20px', textAlign: 'center', marginBottom: '366px' }}>
        <Typography variant="h4" gutterBottom style={{ marginBottom: '30px' }}>
          {userName}, here is your Pali {virtualFriendData.name}!
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {virtualFriendData.imageUrl && (
              <img src={virtualFriendData.imageUrl} alt="Virtual Friend" style={{ width: '50%', height: 'auto', marginBottom: '10px', border: '4px solid black' }} />
            )}
            <Button onClick={() => navigateToEdit('imagedescription')} variant="contained" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>Edit Image</Button>
          </Grid>
          {Object.entries({
            "Friend Name": virtualFriendData.name || 'Not Set',
            "Relationship Status": virtualFriendData.relationship,
            "Personality Traits": virtualFriendData.traits?.join(', '),
            "General Interests": virtualFriendData.interests?.join(', '),
            "Communication Style": virtualFriendData.communicationStyle,
            "Backstory": virtualFriendData.backstory,
            "Friend Texts First": virtualFriendData.virtualTextMessaging === 'yes' ? `Yes (${messageTime})` : 'No',
          }).map(([key, value], index) => (
            <Grid item xs={12} sm={6} key={index} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <Paper elevation={3} sx={{ padding: '40px', width: '100%', textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{key}:</Typography>
                <Typography variant="body1">{value}</Typography>
                <Button onClick={() => navigateToEdit(key.toLowerCase().replace(/\s+/g, ''))} variant="outlined" sx={{ marginTop: '10px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}>Edit</Button>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button 
            onClick={() => navigate('/phonenumber', { state: { virtualFriendId: location.state?.virtualFriendId } })} 
            variant="outlined" 
            sx={{ marginRight: '10px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}
          >
            Go Back
          </Button>          
          <Button onClick={finalizeSettings} variant="contained" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>Finalize Settings</Button>
        </div>
      </Container>

      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
            © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={6} sm={3}>
            <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
          </Grid>
          <Grid item xs={6} sm={3}>
            <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Summary;
