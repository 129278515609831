import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Typography, Button, Container, AppBar, Toolbar, Grid, Paper } from '@mui/material';
import SignUp from './SignUp';
import Tutorial from './Tutorial';
import AICustomization from './AICustomization';
import Backstory from './Backstory';
import Summary from './Summary';
import AccountManagement from './AccountManagement';
import Login from './Login';
import ManageFriends from './ManageFriends';
import Friend from './Friend';
import UserSettings from './UserSettings';
import FinalPage from './FinalPage';
import OnboardingPage from './OnboardingPage';
import CompanionType from './CompanionType';
import RelationshipStatus from './RelationshipStatus';
import PersonalityTraits1 from './PersonalityTraits1';
import PersonalityTraits2 from './PersonalityTraits2';
import Interests1 from './Interests1';
import Interests2 from './Interests2';
import CommunicationStyle from './CommunicationStyle';
import ImageDescription from './ImageDescription';
import TextMessagingOptIn from './TextMessagingOptIn';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import HowItWorksPage from './HowItWorksPage';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import EULAPage from './EULAPage';
import AboutUsPage from './AboutUsPage';
import PricingPage from './PricingPage';
import GoPremiumPage from './GoPremiumPage';
import MyInformation from './MyInformation';
import PasswordReset from './PasswordReset';
import EditSummary from './EditSummary';
import EditPaliType from './editpalitype';
import EditRelationshipType from './EditRelationshipType';
import EditPersonality1 from './EditPersonality1';
import EditPersonality2 from './EditPersonality2';
import PaliInterests1 from './PaliInterests1';
import PaliInterests2 from './PaliInterests2';
import EditCommStyle from './EditCommStyle';
import EditBackstory from './EditBackstory';
import EditTextOptIn from './EditTextOptIn';
import EditPaliName from './EditPaliName';
import PremiumSignup from './PremiumSignup';
import PremiumSignup2 from './PremiumSign2';
import ErrorPage from './error';
import PhoneNumberPage from './phonenumberpage';
import PublicPrivacyPolicy from './publicpolicy';
import PublicTermsAndConditions from './publicterms';
import './App.css';

function App() {
    return (
        <Router>
                <Routes>
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/" element={<HomePage />} /> {/* Set HomePage as the root route */}
                    <Route path="/tutorial" element={<Tutorial />} />
                    <Route path="/customize" element={<AICustomization />} />
                    <Route path="/companiontype" element={<CompanionType />} />
                    <Route path="/relationshipstatus" element={<RelationshipStatus />} />
                    <Route path="/personality1" element={<PersonalityTraits1 />} />
                    <Route path="/personality2" element={<PersonalityTraits2 />} />
                    <Route path="/interests1" element={<Interests1 />} />
                    <Route path="/interests2" element={<Interests2 />} />
                    <Route path="/commstyle" element={<CommunicationStyle />} />
                    <Route path="/backstory" element={<Backstory />} />
                    <Route path="/imagegen" element={<ImageDescription />} />
                    <Route path="/getmessage" element={<TextMessagingOptIn />} />
                    <Route path="/summary" element={<Summary />} />
                    <Route path="/account" element={<AccountManagement />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/managefriends" element={<ManageFriends />} />
                    <Route path="/friend" element={<Friend />} />
                    <Route path="/settings" element={<UserSettings />} />
                    <Route path="/friend/:friendId" element={<Friend />} />
                    <Route path="/final" element={<FinalPage />} />
                    <Route path="/onboard" element={<OnboardingPage />} />
                    <Route path="/aboutpage" element={<AboutPage />} />
                    <Route path="/howitworks" element={<HowItWorksPage />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/EULA" element={<EULAPage />} />
                    <Route path="/aboutdream" element={<AboutUsPage />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/gopremium" element={<GoPremiumPage />} />
                    <Route path="/myinfo" element={<MyInformation />} />
                    <Route path="/passwordreset" element={<PasswordReset />} />
                    <Route path="/editpali" element={<EditSummary />} />
                    <Route path="/editpalitype" element={<EditPaliType />} />
                    <Route path="/editrelationship" element={<EditRelationshipType />} />
                    <Route path="/editpersonality1" element={<EditPersonality1 />} />
                    <Route path="/editpersonality2" element={<EditPersonality2 />} />
                    <Route path="/PaliInterests1" element={<PaliInterests1 />} />
                    <Route path="/PaliInterests2" element={<PaliInterests2 />} />
                    <Route path="/EditCommStyle" element={<EditCommStyle />} />
                    <Route path="/EditBackstory" element={<EditBackstory />} />
                    <Route path="/EditTextOptIn" element={<EditTextOptIn />} />
                    <Route path="/EditPaliName" element={<EditPaliName />} />
                    <Route path="/premsignup" element={<PremiumSignup />} />
                    <Route path="/premsignup2" element={<PremiumSignup2 />} />
                    <Route path="/phonenumber" element={<PhoneNumberPage />} />
                    <Route path="/publicprivacy" element={<PublicPrivacyPolicy />} />
                    <Route path="/publicterms" element={<PublicTermsAndConditions />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
        </Router>
    );
}

export default App;
