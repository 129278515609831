import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, updateDoc, getFirestore } from 'firebase/firestore';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid, Paper } from '@mui/material';
import { ChatBubbleOutline, PhoneAndroid, ArrowForward } from '@mui/icons-material';
import useScrollToTop from './useScrollToTop';

function PhoneNumberPage() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();
    const auth = getAuth();
    const phoneUtil = PhoneNumberUtil.getInstance();
    const [virtualFriendId, setVirtualFriendId] = useState(null);
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setUserName(userSnap.data().name);
                    setVirtualFriendId(location.state?.virtualFriendId);
                } else {
                    alert("User data not found.");
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [auth, db, navigate, location.state]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleSubmit = async () => {
        if (phoneNumber === '') {
            alert('Please enter your phone number.');
            return;
        }

        let formattedNumber;
        try {
            const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US');
            if (!phoneUtil.isValidNumber(parsedNumber)) {
                alert('Invalid phone number. Please enter a valid number.');
                return;
            }
            formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
        } catch (err) {
            alert('Failed to parse phone number.');
            return;
        }

        try {
            const userRef = doc(db, 'users', auth.currentUser.uid);

            // Update the document with the phone number
            await updateDoc(userRef, {
                phoneNumber: formattedNumber
            });

            navigate('/summary', { state: { virtualFriendId } });
        } catch (error) {
            console.error("Error updating phone number: ", error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="sm" style={{ flex: 1, marginTop: '100px', padding: '20px', textAlign: 'center', paddingBottom: '150px' }}>
                <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Pali Logo" style={{ width: '15%', height: 'auto', marginBottom: '20px' }} />
                <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
                    Almost done! We just need your phone number to get the conversation started.
                </Typography>
                <Typography variant="body1" gutterBottom style={{ marginBottom: '20px' }}>
                    At Pali, all your conversations happen through standard text messages. We need your phone number to get the conversation started and keep the chat going.
                </Typography>
                <Typography variant="body1" gutterBottom style={{ marginBottom: '20px' }}>
                    <i>Your phone number will never be shared with anyone, for any reason.</i>
                </Typography>
                <TextField
                    label="Enter your Phone Number"
                    type="tel"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        margin: '20px 0',
                        width: '100%',
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Next
                </Button>
                <Button
                    variant="outlined"
                    onClick={goBack}
                    sx={{
                        width: '100%',
                        color: 'black',
                        borderColor: 'black',
                        '&:hover': {
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
                <Paper elevation={3} style={{ padding: '20px', marginTop: '40px' }}>
                    <Typography variant="h5" style={{ marginBottom: '20px', color: '#ff7f50ff' }}>
                        Why We Need Your Phone Number
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <ChatBubbleOutline style={{ fontSize: '3rem', color: '#ff7f50ff' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Your <b>Pali</b> sends messages via text to connect with you.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <PhoneAndroid style={{ fontSize: '3rem', color: '#ff7f50ff' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                We need your number to start and maintain the chat.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <ArrowForward style={{ fontSize: '3rem', color: '#ff7f50ff' }} />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Your number is private and secure, used only for Pali texts.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default PhoneNumberPage;
