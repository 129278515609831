import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, updateDoc, getFirestore } from 'firebase/firestore';
import { AppBar, Toolbar, Button, Typography, Container, TextField, Grid, Paper } from '@mui/material';
import useScrollToTop from './useScrollToTop';

function OnboardingPage() {
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setName(userSnap.data().name);
                }
            } else {
                navigate('/login');
            }
        });

        return unsubscribe; // Cleanup subscription on component unmount
    }, [auth, db, navigate]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleSubmit = async () => {
        if (name === '') {
            alert('Please enter your name.');
            return;
        }

        try {
            const userRef = doc(db, 'users', auth.currentUser.uid);

            // Update the document with the name field
            await updateDoc(userRef, {
                name
            });

            navigate('/customize');
        } catch (error) {
            console.error("Error during onboarding: ", error);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="md" style={{ marginTop: '100px', padding: '20px', textAlign: 'center', flexGrow: 1, marginBottom: '40px' }}>
                <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Pali Logo" style={{ width: '15%', height: 'auto', marginBottom: '20px' }} />
                <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
                    Welcome to <b>Pali!</b> Having someone to talk with is important. 
                </Typography>
                <Typography variant="h5" gutterBottom style={{ marginBottom: '20px' }}>
                    Let's get started creating your ideal personal companion you can text anytime. What is your name?
                </Typography>
                <TextField
                    label="Enter your Name"
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <Typography variant="h6" gutterBottom style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <i>At Pali, your personal details and conversations are kept 100% private and will never be shared with anyone, for any reason.</i>
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        margin: '20px 0',
                        width: '100%',
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Next
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => navigate('/account')}
                    sx={{
                        width: '100%',
                        marginBottom: '20px',
                        color: 'black',
                        borderColor: 'black',
                        '&:hover': {
                            backgroundColor: '#ff7f50ff'
                        }
                    }}
                >
                    Go Back
                </Button>
            </Container>

            <Container maxWidth="md" style={{ marginTop: '40px', marginBottom: '500px', textAlign: 'center' }}>
                <Typography variant="h5" style={{ marginBottom: '30px', color: 'black' }}>
                    <b>Steps to Create Your Pali:</b>
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {[
                        { title: "Customize Your Pali", description: "Design your Pali's personality, appearance, and background story to match your preferences." },
                        { title: "Choose Your Messaging Preferences", description: "Select how and when you want to receive messages from your Pali to best suit your lifestyle." },
                        { title: "Start Chatting", description: "Begin your journey with Pali. Start having meaningful conversations anytime, anywhere." },
                    ].map((step, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex' }}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>{step.title}</b></Typography>
                                <Typography variant="body2" style={{ color: 'black' }}>
                                    {step.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default OnboardingPage;
