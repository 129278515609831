import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, FormControlLabel, Checkbox, FormGroup, Select, MenuItem, Grid } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import useScrollToTop from './useScrollToTop';

function EditTextOptIn() {
  const [optIn, setOptIn] = useState(null); // Updated to handle three states: 'yes', 'no', or null
  const [messageTime, setMessageTime] = useState('');
  const [userTimeZone, setUserTimeZone] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState('');
  const db = getFirestore();
  const auth = getAuth();
  useScrollToTop();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch user's name
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserName(userSnap.data().name);
        }

        // Fetch existing text messaging settings
        if (location.state && location.state.virtualFriendId) {
          const virtualFriendRef = doc(db, 'users', user.uid, 'virtualFriends', location.state.virtualFriendId);
          const virtualFriendSnap = await getDoc(virtualFriendRef);
          if (virtualFriendSnap.exists()) {
            const data = virtualFriendSnap.data();
            setOptIn(data.virtualTextMessaging === 'yes' ? 'yes' : 'no');
            setMessageTime(data.preferredMessageTime || '');
            setUserTimeZone(data.userTimeZone || '');
          }
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    });
  }, [auth, db, navigate, location.state]);

  if (!location.state || !location.state.virtualFriendId) {
    alert("Invalid access. Redirecting to start.");
    navigate("/login");
    return null;
  }

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };

  const handleOptInChange = (event) => {
    setOptIn(event.target.checked ? 'yes' : 'no');
  };

  const handleSubmit = async () => {
    // First, check if the user has opted in but hasn't selected both message time and timezone
    if (optIn === 'yes' && (messageTime === '' || userTimeZone === '')) {
      alert('Please select both your preferred message time and timezone.');
      return;
    }
  
    // Then, check if the user has made a preference selection
    if (optIn === null) {
      alert('Please select your preferences.');
      return;
    }
  
    const virtualFriendId = location.state.virtualFriendId;
    const virtualFriendRef = doc(db, 'users', auth.currentUser.uid, 'virtualFriends', virtualFriendId);
  
    try {
      await setDoc(virtualFriendRef, {
        virtualTextMessaging: optIn,
        preferredMessageTime: messageTime,
        userTimeZone: userTimeZone
      }, { merge: true });
      navigate('/editpali', { state: { virtualFriendId } });
    } catch (error) {
      console.error("Error updating virtual text messaging settings: ", error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  // Continuing from the previous part...

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>
      <Container maxWidth="md" style={{ flex: 1, marginTop: '100px', padding: '40px', textAlign: 'center', marginBottom: '200px' }}>
        <Typography variant="h4" style={{ marginBottom: '30px' }}>Do you want to receive daily messages from your Pali?</Typography>
        <Typography variant="body1" style={{ marginBottom: '30px' }}>With this feature enabled, sometimes your Pali will text you first, or ask you how your day is going so far. They may even follow up on the last message they sent! The time slot you choose will be the time of day the Pali will look to send an impromptu message your way.</Typography>
        
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={optIn === 'yes'} onChange={() => setOptIn('yes')} />}
            label="Yes, I want to receive daily messages."
          />
          <FormControlLabel
            control={<Checkbox checked={optIn === 'no'} onChange={() => setOptIn('no')} />}
            label="No, I do not want this feature."
          />
        </FormGroup>

        {optIn === 'yes' && (
          <>
            <Container style={{ marginTop: '20px' }}>
              <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                Select a preferred time for messages:
              </Typography>
              <Select
                id="messageTime"
                value={messageTime}
                onChange={(e) => setMessageTime(e.target.value)}
                fullWidth
                displayEmpty
                variant="outlined"
              >
                <MenuItem value=""><em>Select Time Slot</em></MenuItem>
                <MenuItem value="early morning">Early Morning</MenuItem>
                <MenuItem value="morning">Morning</MenuItem>
                <MenuItem value="afternoon">Afternoon</MenuItem>
                <MenuItem value="evening">Evening</MenuItem>
                <MenuItem value="late night">Late Night</MenuItem>
              </Select>
            </Container>

            <Container style={{ marginTop: '20px' }}>
              <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
                Select your timezone:
              </Typography>
              <Select
                id="userTimeZone"
                value={userTimeZone}
                onChange={(e) => setUserTimeZone(e.target.value)}
                fullWidth
                displayEmpty
                variant="outlined"
              >
                <MenuItem value=""><em>Select Timezone</em></MenuItem>
                <MenuItem value="PST">Pacific Time (PST)</MenuItem>
                <MenuItem value="MST">Mountain Time (MST)</MenuItem>
                <MenuItem value="CST">Central Time (CST)</MenuItem>
                <MenuItem value="EST">Eastern Time (EST)</MenuItem>
                {/* Add more timezones as needed */}
              </Select>
            </Container>
          </>
        )}

        <div style={{ marginTop: '20px' }}>
          <Button 
            variant="outlined" 
            onClick={goBack}
            sx={{ margin: '10px', height: '50px', color: 'black', borderColor: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}
          >
            Go Back
          </Button>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            sx={{ margin: '10px', height: '50px', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}
          >
            Save Changes
          </Button>
        </div>
      </Container>

      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
            © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default EditTextOptIn;
