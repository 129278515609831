import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, Divider, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import useScrollToTop from './useScrollToTop';


const PremiumSignup = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const auth = getAuth();
  const location = useLocation();
  const db = getFirestore();
  const functions = getFunctions();
  const stripePromise = loadStripe('pk_live_51Mw39FGvdtye7cZUJcRfirgbzZZWdOnBqZv1GaoxwVWQb3ULkctFGG1qW0BFtdDLREtGoYs36x87NQxXGEyAbMfe000sbk1ntU');
  const [virtualFriendId, setVirtualFriendId] = useState(null);
  const [loading, setLoading] = useState({}); // Adjusted to manage loading state for each button
  useScrollToTop();


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => { // Made async for fetching user tier
      if (!user) {
        navigate('/login');
      } else {
        // Existing logic to retrieve the virtualFriendId
        let virtualFriendId = location.state?.virtualFriendId;
        if (!virtualFriendId) {
          const queryParams = new URLSearchParams(location.search);
          virtualFriendId = queryParams.get('virtualFriendId');
        }
        if (!virtualFriendId) {
          virtualFriendId = localStorage.getItem('virtualFriendId');
        }
        if (virtualFriendId) {
          console.log("virtualFriendId available:", virtualFriendId);
          localStorage.setItem('virtualFriendId', virtualFriendId);
          setVirtualFriendId(virtualFriendId);
        } else {
          console.log("No virtualFriendId found.");
        }
  
        // New logic to check user tier
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Navigate to "/final" if the user is on a paid tier
          if (userData.tier && userData.tier !== 'Free') {
            navigate('/final');
          } // No else block needed, if user is 'Free' or undefined, stay on the page
        } else {
          console.log('User document does not exist');
          // Optionally handle the case where user data isn't found
        }
      }
    });
    return () => unsubscribe();
  }, [auth, db, navigate, location.state, location.search]);
  
  

  const tiers = [
    { name: 'Basic', priceId: 'price_1OyMIEGvdtye7cZUC1UoV9OZ', price: '$8/month', texts: '150 texts/month' },
    { name: 'Plus', priceId: 'price_1OyMIMGvdtye7cZUfWgtOt3s', price: '$15/month', texts: '450 texts/month' },
    { name: 'Premium', priceId: 'price_1OyMISGvdtye7cZUSXYUmEvN', price: '$30/month', texts: '900 texts/month' },
    { name: 'Pro', priceId: 'price_1OyMIVGvdtye7cZUL84bE4L2', price: '$45/month', texts: '1500 texts/month' },
  ];

  const handleLogout = () => {
    signOut(auth).then(() => navigate('/login')).catch((error) => console.error('Logout Error:', error));
  };

  const handleStripeRedirect = async (priceId) => {
    setLoading(prev => ({ ...prev, [priceId]: true })); // Start loading for specific tier
    try {
      const stripe = await stripePromise;
      const virtualFriendId = localStorage.getItem('virtualFriendId') || 'defaultId';
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const result = await createCheckoutSession({ priceId, virtualFriendId });
      await stripe.redirectToCheckout({
        sessionId: result.data.sessionId
      });
    } catch (error) {
      console.error("Error creating checkout session:", error.message);
      alert("Error processing your request: " + error.message);
    } finally {
      setLoading(prev => ({ ...prev, [priceId]: false })); // End loading for specific tier
    }
  };


  return (
    <>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>

      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', marginTop: '1px' }}>
        <Container maxWidth={false} style={{ flexGrow: 1, padding: '160px 20px', backgroundColor: '#eeebea', textAlign: 'center' }}>
          <Typography variant="h2" style={{ color: '#ff7f50ff', marginBottom: '30px' }}><b>Start chatting with your Pali now!</b></Typography>
          <Typography variant="h3" style={{ color: '#ff7f50ff', marginBottom: '50px' }}>Unlock the full experience and <b>never miss a moment.</b></Typography>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {tiers.map((tier, index) => (
              <Grid item key={index} xs={12} sm={6} md={3}>
                <Paper elevation={3} style={{ padding: '120px', backgroundColor: 'white', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff', color: 'white' }, cursor: 'pointer', height: '100%' }}>
                  <Typography variant="h5" style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '2.25rem' }}>{tier.name}</Typography>
                  <Typography variant="body1" style={{ marginBottom: '15px', fontSize: '2.1rem' }}>{tier.price}</Typography>
                  <Typography variant="body2" style={{ marginBottom: '20px', fontSize: '1.5rem' }}>{tier.texts}</Typography>
              <Button
                variant="contained"
                onClick={() => handleStripeRedirect(tier.priceId)}
                disabled={loading[tier.priceId]} // Use specific loading state for this button
                style={{
                  backgroundColor: '#ff7f50ff',
                  color: 'white',
                  fontWeight: 'bold',
                  padding: '20px 25px',
                  fontSize: '1.560rem',
                }}
              >
                {loading[tier.priceId] ? <CircularProgress size={24} color="inherit" /> : `Choose ${tier.name}`}
              </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Divider style={{ backgroundColor: 'black', height: '2px' }} />

        <Container maxWidth={false} style={{ backgroundColor: '#6495edff', padding: '250px 20px', textAlign: 'center', width: '100%' }}>
          <Typography variant="h3" style={{ color: 'black', marginBottom: '30px', fontWeight: 'bold' }}>Not ready to upgrade? All users get 10 free messages to get started.</Typography>
          <Typography variant="h5" style={{ color: 'black', marginBottom: '20px', fontWeight: 'bold' }}>Start Chatting Today!</Typography>
          <Button
  variant="contained"
  onClick={() => navigate("/final", { state: { virtualFriendId } })}
  style={{
    backgroundColor: '#ff7f50ff',
    color: 'white',
    fontWeight: 'bold',
    padding: '20px 25px',
    fontSize: '1.560rem',
  }}
>
  Try It Out
</Button>
        </Container>

        <Divider style={{ backgroundColor: 'black', height: '2px', margin: '0 auto', width: '100%' }} />

        <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
          <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>© 2024 Dreamforge LLC</Typography>
          <Grid container justifyContent="center">
          <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default PremiumSignup;
