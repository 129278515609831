import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SchoolIcon from '@mui/icons-material/School';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import useScrollToTop from './useScrollToTop';

function AccountManagement() {
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const [userName, setUserName] = useState('');
    const [userTier, setUserTier] = useState('');
    const [messageCount, setMessageCount] = useState(0);
    useScrollToTop();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, 'users', user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    setUserName(userData.name);
                    const userTier = userData.tier || 'Free';
                    setUserTier(userTier);
                    const userMessageCount = userData.messageCount !== undefined ? userData.messageCount : 0;
                    setMessageCount(userMessageCount);
                }
            } else {
                navigate('/login');
            }
        });
        return unsubscribe;
    }, [auth, db, navigate]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const tierMessageLimits = {
        Free: 10,
        Basic: 150,
        Plus: 450,
        Premium: 950,
        Pro: 1500,
    };

    const messagesLeft = tierMessageLimits[userTier] - messageCount;
    const messagesLeftText = `You are on the ${userTier} tier and have ${messagesLeft} messages left this month!`;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <Link to="/account" style={{ marginRight: '10px' }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
                    </Link>
                    <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
                    <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
                    <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="md" style={{ flex: 1, marginTop: '100px', padding: '20px', textAlign: 'center', flexGrow: 1, paddingBottom: '100px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '30px', fontWeight: 'bold', fontSize: '2.8rem', color: '#333' }}>
                    Welcome to Your Account, {userName}
                </Typography>
                <Typography variant="h5" style={{ marginBottom: '40px', color: '#555' }}>
                    {messagesLeftText}
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {[
                        { label: 'Manage Friends', icon: <PeopleAltIcon style={{ fontSize: 60 }} />, onClick: () => navigate('/managefriends') },
                        { label: 'User Settings', icon: <SettingsIcon style={{ fontSize: 60 }} />, onClick: () => navigate('/settings') },
                        { label: 'Create New Pali', icon: <AddCircleOutlineIcon style={{ fontSize: 60 }} />, onClick: () => navigate('/customize') },
                        { label: 'Log Out', icon: <ExitToAppIcon style={{ fontSize: 60 }} />, onClick: handleLogout },
                        { label: 'Tutorial', icon: <SchoolIcon style={{ fontSize: 60 }} />, onClick: () => navigate('/tutorial') },
                    ].map((tile, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <Paper
                                style={{
                                    padding: '30px',
                                    backgroundColor: 'black',
                                    color: 'white',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '200px',
                                    transition: 'transform 0.3s, backgroundColor 0.3s',
                                }}
                                elevation={3}
                                onClick={tile.onClick}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = '#ff7f50ff';
                                    e.currentTarget.style.transform = 'scale(1.05)';
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = 'black';
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            >
                                {tile.icon}
                                <Typography variant="h6" style={{ marginTop: '15px', fontWeight: 'bold', fontSize: '1.2rem' }}>
                                    {tile.label}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default AccountManagement;
