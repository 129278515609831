import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Paper, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged, updateEmail, deleteUser } from 'firebase/auth';
import { doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import useScrollToTop from './useScrollToTop';

const MyInformation = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const [userData, setUserData] = useState({ name: '', email: '', phoneNumber: '' });
    const [emailEditable, setEmailEditable] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    useScrollToTop();

    useEffect(() => {
        const fetchUserData = async () => {
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const userRef = doc(db, "users", user.uid);
                    const docSnap = await getDoc(userRef);
                    if (docSnap.exists()) {
                        setUserData(docSnap.data());
                    } else {
                        console.log("No such document!");
                    }
                } else {
                    navigate("/login");
                }
            });
        };
        fetchUserData();
    }, [auth, db, navigate]);

    const handleLogout = () => {
        signOut(auth).then(() => navigate('/login')).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    const handleFieldChange = (field, value) => {
        setUserData({ ...userData, [field]: value });
    };

    const handleUpdate = async () => {
        try {
            const user = auth.currentUser;
            if (emailEditable && userData.email !== user.email) {
                await updateEmail(user, userData.email);
                setEmailEditable(false);
            }
            await setDoc(doc(db, 'users', user.uid), userData, { merge: true });
            alert('User information updated successfully.');
        } catch (error) {
            console.error('Error updating user information:', error);
            alert('Failed to update user information.');
        }
    };

    const handleDeleteAccount = async () => {
        const user = auth.currentUser;
        try {
            await deleteUser(user);
            alert('Your account has been deleted.');
            navigate('/login');
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('Failed to delete account. Please try again.');
        }
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>

            <Container component="main" maxWidth="md" style={{ flex: 1, marginTop: '120px', padding: '40px', textAlign: 'center', marginBottom: '20px' }}>
                <Typography variant="h4" gutterBottom style={{ marginBottom: '30px' }}>
                    My Information
                </Typography>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <TextField
                        label="Name"
                        type="text"
                        value={userData.name}
                        onChange={(e) => handleFieldChange('name', e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Email"
                        type="email"
                        value={userData.email}
                        onChange={(e) => handleFieldChange('email', e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        disabled={!emailEditable}
                    />
                    <Button
                        variant="text"
                        onClick={() => setEmailEditable(true)}
                        sx={{ margin: '10px 0', color: 'blue' }}
                    >
                        Edit Email
                    </Button>
                    <TextField
                        label="Phone Number"
                        type="tel"
                        value={userData.phoneNumber}
                        onChange={(e) => handleFieldChange('phoneNumber', e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        onClick={handleUpdate}
                        sx={{
                            margin: '20px 0',
                            width: '100%',
                            backgroundColor: 'black',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#ff7f50ff'
                            }
                        }}
                    >
                        Update Information
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenDeleteDialog(true)}
                        sx={{
                            margin: '20px auto',
                            display: 'block', // Center the button
                        }}
                    >
                        Delete My Account
                    </Button>
                </Paper>
                <Button
                    variant="outlined"
                    onClick={() => navigate('/settings')}
                    sx={{
                        width: '100%',
                        color: 'black',
                        borderColor: 'black',
                        '&:hover': {
                            backgroundColor: '#ff7f50ff',
                            borderColor: '#ff7f50ff',
                            color: 'white',
                        },
                        marginTop: '20px',
                    }}
                >
                    Back to User Settings
                </Button>
            </Container>

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you really want to delete your account?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you do this, we cannot recover it.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteAccount} autoFocus>
                        I Understand
                    </Button>
                </DialogActions>
            </Dialog>

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default MyInformation;
