import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, CircularProgress, Paper } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { ChatBubbleOutline, PhoneAndroid, ArrowForward } from '@mui/icons-material';
import useScrollToTop from './useScrollToTop';

// Initialize functions
const functions = getFunctions();
const generateAndSendText = httpsCallable(functions, 'generateAndSendText');

function FinalPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const db = getFirestore();
  const [userName, setUserName] = useState('');
  const [friendData, setFriendData] = useState({});
  const [loading, setLoading] = useState(false);

  // Retrieve virtualFriendId from location state
  const [virtualFriendId, setVirtualFriendId] = useState(null);
  const sendVirtualFriendImage = httpsCallable(functions, 'sendVirtualFriendImage'); // Add this line
  useScrollToTop();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/login');
        return;
      }

      let id = location.state?.virtualFriendId ||
               new URLSearchParams(location.search).get('virtualFriendId') ||
               localStorage.getItem('virtualFriendId');

      // Update the virtualFriendId state
      setVirtualFriendId(id);

      if (id) {
        // Proceed with fetching friend data and user name using id
        const friendRef = doc(db, 'users', user.uid, 'virtualFriends', id);
        const friendSnap = await getDoc(friendRef);
        if (friendSnap.exists()) {
          setFriendData(friendSnap.data());
        } else {
          console.error("Friend data not found.");
        }

        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserName(userSnap.data().name);
        }
      } else {
        console.error("Virtual friend ID not found.");
      }
    });

    return () => unsubscribe();
  }, [auth, db, navigate, location.state, location.search]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };

  const goBack = () => {
    navigate('/summary', { state: { virtualFriendId: virtualFriendId } });
  };

  const generateFriend = async () => {
    // Direct use of state variable
    if (!virtualFriendId) {
      console.error("Virtual friend ID is missing.");
      return;
    }
    setLoading(true);

    try {
      const result = await generateAndSendText({ virtualFriendId }); // Use state variable directly
      const generatedText = result.data.generatedText;
      console.log("Generated text:", generatedText);
      alert("Generated text: " + generatedText);
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendImageViaText = async () => {
    if (!virtualFriendId) {
      console.log("Virtual friend ID is missing.");
      return;
    }
    setLoading(true);

    try {
      await sendVirtualFriendImage({ virtualFriendId });
      alert("Image sent to your phone!");
    } catch (error) {
      console.error("Error sending image: ", error);
      alert("Failed to send image.");
    }
    setLoading(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>

      <Container component="main" maxWidth="md" style={{ flex: 1, marginTop: '120px', padding: '40px', textAlign: 'center', marginBottom: '50px' }}>
        <Typography variant="h3" gutterBottom style={{ marginBottom: '10px', color: '#ff7f50ff', }}>
          <b>Your Pali is ready!</b> 
        </Typography>
        <Typography variant="h4" gutterBottom style={{ marginBottom: '30px', color: '#ff7f50ff', }}>
          {userName}, start the conversation with <b>{friendData.name}!</b>
        </Typography>
        {friendData.imageUrl && (
          <img src={friendData.imageUrl} alt={friendData.name} style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px', border: '4px solid black' }} />
        )}
        <Typography variant="body1" gutterBottom style={{ marginBottom: '10px', fontSize: '1.25rem', color: '#ff7f50ff', }}>
          Press this button and your <b>Pali</b> will <i>text you!</i>
        </Typography>
        <Button onClick={generateFriend} variant="contained" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, marginBottom: '20px', width: '80%', fontSize: '1.2rem' }} disabled={loading}>
          {loading ? <CircularProgress color="inherit" /> : 'Start the Conversation'}
        </Button>
        <Typography variant="body1" gutterBottom style={{ marginBottom: '10px', color: '#ff7f50ff', fontSize: '1.25rem', }}>
          Want to use the friend's picture as your contact picture on your phone?
        </Typography>
        <Button onClick={sendImageViaText} variant="contained" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, marginBottom: '20px', width: '80%', fontSize: '1.2rem' }}>
          Send me a Picture!
        </Button>
        <Button onClick={() => navigate('/managefriends')} variant="contained" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, width: '80%', fontSize: '1.2rem' }}>
          Manage Friends
        </Button>
        <Paper elevation={3} style={{ padding: '20px', marginTop: '40px' }}>
          <Typography variant="h5" style={{ marginBottom: '20px', color: '#ff7f50ff' }}>
            How Pali Works
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <ChatBubbleOutline style={{ fontSize: '3rem', color: '#ff7f50ff' }} />
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                Press the <b>Start the Conversation</b> button to get your first text from your Pali.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <PhoneAndroid style={{ fontSize: '3rem', color: '#ff7f50ff' }} />
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                Your Pali will text you on your phone, and you can respond to continue the conversation.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ArrowForward style={{ fontSize: '3rem', color: '#ff7f50ff' }} />
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                Enjoy interacting with your Pali and building a meaningful connection through text messages.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default FinalPage;
