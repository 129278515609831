import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, CircularProgress } from '@mui/material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import useScrollToTop from './useScrollToTop';
import { getFunctions, httpsCallable } from 'firebase/functions';


function UserSettings() {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(false);

  useScrollToTop();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserName(userSnap.data().name); // Assuming 'name' field holds the user's name
        }
      } else {
        navigate('/login');
      }
    });

    return unsubscribe; // Cleanup subscription on component unmount
  }, [auth, db, navigate]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };

  const handleManageSubscription = async () => {
    setLoading(true); // Start loading before the async operation
    const functions = getFunctions();
    const getPortalUrl = httpsCallable(functions, 'getStripeCustomerPortalUrl');
  
    try {
      const { data } = await getPortalUrl();
      window.location.href = data.url; // Navigate to the Stripe Customer Portal
    } catch (error) {
      console.error("Error getting Stripe Customer Portal URL:", error);
      alert("Please sign up for a premium account to access subscription settings by navigating to the Premium button in the top left of the screen.");
    } finally {
      setLoading(false); // Stop loading after the async operation is complete
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
<AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
    <Toolbar>
        <Link to="/account" style={{ marginRight: '10px' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ height: '35px', marginRight: '20px' }} />
        </Link>
        <Link to="/account" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
        <Link to="/managefriends" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Friends</Link>
        <Link to="/settings" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Settings</Link>
        <Button onClick={handleLogout} variant="outlined" color="inherit" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Logout</Button>
        <Button variant="contained" component={Link} to="/premsignup2" sx={{ backgroundColor: '#ff7f50ff', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Pro</Button>
    </Toolbar>
</AppBar>
      <Container component="main" maxWidth="md" style={{ flex: 1, marginTop: '120px', padding: '40px', textAlign: 'center', marginBottom: '80px' }}>
        <Typography variant="h4" gutterBottom style={{ marginBottom: '30px' }}>
          User Settings
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
          <Button onClick={handleManageSubscription} variant="outlined" sx={{ margin: '10px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Manage Subscription'}
        </Button>            <Button onClick={() => navigate('/myinfo')} variant="outlined" sx={{ margin: '10px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}>My Information</Button>
            <Button onClick={() => navigate('/terms')} variant="outlined" sx={{ margin: '10px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}>Terms and Conditions</Button>
            <Button onClick={() => navigate('/privacy')} variant="outlined" sx={{ margin: '10px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}>Privacy Policy</Button>
<Button onClick={() => navigate('/eula')} variant="outlined" sx={{ margin: '10px', borderColor: 'black', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' } }}>EULA</Button>


          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => navigate('/account')} variant="contained" sx={{ margin: '10px', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>Go Back</Button>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '10px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
        <Typography variant="body1" style={{ color: 'white', marginBottom: '15px', fontWeight: 'bold' }}>
          © 2024 Dreamforge LLC
        </Typography>
        <Grid container justifyContent="center">
        <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
    </Grid>
    <Grid item xs={6} sm={3}>
        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
    </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default UserSettings;
