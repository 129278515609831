import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Paper, Divider } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PaymentIcon from '@mui/icons-material/Payment';
import ChatIcon from '@mui/icons-material/Chat';
import useScrollToTop from './useScrollToTop';

const HowItWorksPage = () => {
    useScrollToTop();
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffffff', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Toolbar>
                    <img src="https://firebasestorage.googleapis.com/v0/b/virtual-friends-8afc9.appspot.com/o/APP%20IMAGES%2FHOME%2Ftest%20mobile%20pali%20logo.png?alt=media&token=34adc73a-1971-4158-882b-542cdfa35918" alt="Your Logo" style={{ marginRight: '20px', height: '35px' }} />
                    <Link to="/" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>Home</Link>
                    <Link to="/aboutpage" style={{ textDecoration: 'none', color: 'black', marginRight: '10px', fontWeight: 'bold', fontSize: '0.8rem' }}>About</Link>
                    <Link to="/pricing" style={{ textDecoration: 'none', color: 'black', marginRight: 'auto', fontWeight: 'bold', fontSize: '0.8rem' }}>Pricing</Link>
                    <Button variant="outlined" color="inherit" component={Link} to="/login" sx={{ margin: '0 5px', whiteSpace: 'nowrap', color: 'black', '&:hover': { backgroundColor: '#ff7f50ff' }, fontSize: '0.75rem' }}>Login</Button>
                    <Button variant="contained" component={Link} to="/signup" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' }, whiteSpace: 'nowrap', fontSize: '0.75rem' }}>Sign Up</Button>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="md" style={{ marginTop: '100px', padding: '20px', textAlign: 'center', marginBottom: '40px', flex: 1 }}>
                <Typography variant="h3" gutterBottom style={{ color: 'black', marginBottom: '30px', fontSize: '3.7rem', color: '#ff7f50ff' }}>
                    How does <b>Pali</b> work?
                </Typography>
                <Typography variant="h5" gutterBottom style={{ color: 'black', marginBottom: '20px', fontSize: '1.8rem' }}>
                    Simply create your Pali via the website, tailor their personality, and start texting your way to meaningful conversations!
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <DesignServicesIcon style={{ fontSize: 60 }} />
                            <Typography variant="h6" style={{ marginTop: '10px' }}>Design Your Pali</Typography>
                            <Typography variant="body1">Create your own personalized Pali with customizable settings, communication style and unique backstory.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <PaymentIcon style={{ fontSize: 60 }} />
                            <Typography variant="h6" style={{ marginTop: '10px' }}>Choose Your Plan</Typography>
                            <Typography variant="body1">Select the perfect plan that suits your needs and budget. We offer multiple different plans to get chatting.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                            <ChatIcon style={{ fontSize: 60 }} />
                            <Typography variant="h6" style={{ marginTop: '10px' }}>Send Your First Message</Typography>
                            <Typography variant="body1">Finalize your Pali and start the conversation! Your Pali will text you first and then you talk via text!</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            <Container maxWidth={false} style={{ backgroundColor: '#6495edff', padding: '70px 20px', textAlign: 'center' }}>
                <Typography variant="h4" style={{ color: 'white', marginBottom: '30px' }}>
                    Ready to Meet Your Pali?
                </Typography>
                <Button variant="contained" component={Link} to="/signup" sx={{ marginTop: '10px', padding: '15px 30px', fontSize: '1rem', fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#ff7f50ff' } }}>
                    Sign Up Now
                </Button>
            </Container>

                        <Divider style={{ backgroundColor: 'black', height: '2px' }} />


            <Container maxWidth="md" style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'center' }}>
                <Typography variant="h5" style={{ marginBottom: '30px', color: 'black' }}>
                    Here are some examples of how users use Pali for better mental health:
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Daily Check-Ins</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Users engage in daily check-ins with their Pali to discuss their feelings, set daily goals, and reflect on their experiences, promoting self-awareness and emotional regulation.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Stress Relief</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Users find comfort in talking to their Pali during stressful moments, receiving calming messages, mindfulness prompts, and positive affirmations to help manage anxiety.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#ffffff' }}>
                            <Typography variant="h6" style={{ marginBottom: '10px', color: '#ff7f50ff' }}><b>Personal Growth</b></Typography>
                            <Typography variant="body2" style={{ color: 'black' }}>
                                Users use Pali to set personal development goals, receive motivational messages, and track their progress, fostering a sense of accomplishment and growth.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Divider style={{ backgroundColor: 'black', height: '2px' }} />

            <Container maxWidth={false} style={{ backgroundColor: 'black', padding: '30px', margin: '0', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
                <Typography variant="body1" style={{ color: 'white', marginBottom: '30px', fontWeight: 'bold' }}>
                    © 2024 Dreamforge LLC
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Marketing</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <a href="https://www.discoverpali.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Customer Support</a>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/aboutdream" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to="/howitworks" style={{ color: 'white', textDecoration: 'none' }}>How It Works</Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default HowItWorksPage;
